import React, { ReactNode, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '../../../components/buttons';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';
import PhoneTextField from '../../phone-text-field/PhoneTextField';
import TextLink from '../../text-link';
import { useTranslation } from '../../../hooks/useTranslation';
import { Model, AlertOptions } from './types';
import { FormState } from '../../../hooks/useFormState.types';
import ManageAlertsControls from './ManageAlertsControls';
import isEqual from 'lodash/isEqual';
import { useIsMobile } from '../../../util/style-utils';
import ROUTES from '../../../routes';
import { Dialog, IconButton, MenuItem, Paper, Select } from '@material-ui/core';
import AddUsersModal from '../../ssm/post-enrollment/CoCustomer/AddUsersModal';
import AddPerson from './AddPerson';
import { displayPhoneNumberFormat, toDateString } from '../../../util/format';
import { addDays } from 'date-fns';
import PGEButton from '../../buttons/PGE-Button';
import { Close } from '@material-ui/icons';
import useSelectedAccountParams from '../../../hooks/useSelectedAccountParams';
import { ApolloQueryResult } from 'apollo-client';
import { LinkedPerson } from '../../../__generated__/pge-types';

type Props = {
  path?: string;
  alerts: Array<AlertOptions>;
  form: FormState<Model>;
  defaultFormValues: Model;
  onSubmit: (data: Model) => void;
  personList: any;
  handleChangePerson: (selectedPerson: any) => void;
  selectedPerson: any;
  handleDeletePerson: () => void;
  displayError: string | ReactNode | undefined;
  loggedInPersonId: string | undefined | null;
  refetchPerson: () => Promise<
    ApolloQueryResult<{ getLinkedPersons: LinkedPerson[] }>
  >;
  setRecentlyAddedPerson: React.Dispatch<React.SetStateAction<any>>;
  mainPersonEmail: string | undefined;
  loggedInPersonType: string | undefined | null;
  loggedInPersonName: string | undefined | null;
};

export default function ManageAlertsLanding({
  alerts,
  form,
  defaultFormValues,
  onSubmit,
  personList,
  handleChangePerson,
  selectedPerson,
  handleDeletePerson,
  displayError,
  loggedInPersonId,
  refetchPerson,
  setRecentlyAddedPerson,
  mainPersonEmail,
  loggedInPersonType,
  loggedInPersonName,
}: Props) {
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const isMobile = useIsMobile();
  const [showAddPersonModal, setShowAddPersonModal] = useState(false);
  const [showRemovePersonDialog, setShowRemovePersonDialog] = useState(false);
  const { accountParams } = useSelectedAccountParams();
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography>{t('MANAGE_ALERT_SUB_CONTENT')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant={'h2'} className={classes.colorTextPrimary}>
              {t('MANAGE_ACCOUNT_USERS')}
            </Typography>

            <Typography variant={'body1'} className={classes.colorTextPrimary}>
              {t('MANAGE_ALERT_ADD_PEOPLE_TEXT')}
            </Typography>
            {selectedPerson && selectedPerson.verificationStatus === 'Pending' && (
              <Typography
                variant={'subtitle1'}
                className={classes.colorTextPrimary}
              >
                {richT('EMAIL_SENT_TEXT', {
                  PERSON_NAME: selectedPerson.name,
                  START_DATE: toDateString(
                    JSON.parse(selectedPerson.inputData!).createdOn,
                    'MM/dd/yyyy',
                  ),
                  END_DATE: toDateString(
                    addDays(
                      new Date(JSON.parse(selectedPerson.inputData!).createdOn),
                      3,
                    ),
                    'MM/dd/yyyy',
                  ),
                })}
              </Typography>
            )}
            <Box>
              <Select
                labelId="account-select-label"
                id="account-select-select"
                value={selectedPerson}
                variant="outlined"
                onChange={async e => await handleChangePerson(e.target.value)}
                className={classes.select}
                placeholder={t('SELECT_PERSON')}
              >
                {personList &&
                  personList.map((v: any) => (
                    <MenuItem key={v.encryptedPersonId} value={v}>
                      {v.verificationStatus === 'Pending'
                        ? v.name + t('PENDING_VERIFICATION')
                        : v.name}
                    </MenuItem>
                  ))}
              </Select>
            </Box>

            {displayError && <Typography>{displayError}</Typography>}
            {loggedInPersonType !== 'AUTHUSR' && (
              <Grid container item justify={isMobile ? 'center' : 'flex-end'}>
                <Grid item xs={10} md="auto">
                  <Button
                    color={'primary'}
                    variant={'contained'}
                    className={classes.addPerson}
                    onClick={() => setShowAddPersonModal(true)}
                    data-testid="done"
                  >
                    {t('ADD_PERSON')}
                  </Button>

                  <Button
                    color={'primary'}
                    variant={'contained'}
                    className={classes.deletePerson}
                    onClick={() => setShowRemovePersonDialog(true)}
                    data-testid="done"
                    disabled={
                      selectedPerson?.personId === loggedInPersonId ||
                      selectedPerson?.verificationStatus === 'Pending'
                    }
                  >
                    {t('REMOVE_PERSON')}
                  </Button>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item>
        <Card>
          <CardContent>
            <Typography variant={'h2'} className={classes.colorTextPrimary}>
              {t('CONTACT_INFORMATION')}
            </Typography>
            <Typography variant={'body1'}>{t('PHONE_EMAIL_TEXT')}</Typography>
            <Box>
              <Typography
                variant={'body1'}
                className={classes.colorTextPrimary}
              >
                {selectedPerson && (
                  <>
                    {`${t('EMAIL')}:`}
                    <Typography variant={'body1'} className={classes.boldText}>
                      {form.values.email}
                    </Typography>
                  </>
                )}
              </Typography>
            </Box>
            <Box className={classes.divided}>
              {((selectedPerson &&
                !selectedPerson.isCssUser &&
                selectedPerson.verificationStatus === 'Verified') ||
                (selectedPerson && selectedPerson.personId) ===
                  loggedInPersonId) && (
                <PhoneTextField
                  style={{ width: isMobile ? '100%' : '50%' }}
                  {...form.props('phoneNumber')}
                />
              )}
              {selectedPerson &&
                ((selectedPerson.isCssUser &&
                  selectedPerson.personId !== loggedInPersonId) ||
                  selectedPerson.verificationStatus === 'Pending') && (
                  <>
                    <Typography variant={'body1'}>{`${t(
                      'Phone',
                    )}:`}</Typography>
                    <Typography variant={'body1'} className={classes.boldText}>
                      {displayPhoneNumberFormat(form.values.phoneNumber)}
                    </Typography>
                  </>
                )}
            </Box>
            {form.values.email &&
              form.values.email !== defaultFormValues.email &&
              form.values.phoneNumber &&
              form.values.phoneNumber !== defaultFormValues.phoneNumber && (
                <>
                  <Typography
                    color={'textPrimary'}
                    className={classes.colorTextPrimary}
                    variant={'h4'}
                    style={{ marginTop: 20, width: '100%' }}
                  >
                    {t('CHOOSE_VERIFICATION')}
                  </Typography>
                </>
              )}
            <Box>
              <ManageAlertsControls form={form} alerts={alerts} />
            </Box>
            <Box mt={2} mb={2}>
              <Typography
                variant={'body1'}
                component="span"
                className={classes.footerText}
              >
                {t('ENROLL_IN_TEXT')}
                <TextLink to={ROUTES.PRIVACY_POLICY}>
                  {t('PRIVACY_POLICY_AND_SECURITY')}
                </TextLink>
              </Typography>
            </Box>
            <Box mt={2} mb={2}>
              <Typography
                variant={'body1'}
                component="span"
                className={classes.footerText}
              >
                {t('ENROLL_IN_TEXT_WITH_PHONE_NUMBERS')}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid container item justify={isMobile ? 'center' : 'flex-end'}>
        <Grid item xs={10} md="auto">
          <Button
            disabled={
              isEqual(defaultFormValues, form.values) ||
              form.errors.phoneNumber === t('MANAGE_ALERTS_BAD_PHONE_NUMBER') ||
              (form.values.email === '' && form.values.phoneNumber === '')
            }
            color="secondary"
            variant="contained"
            onClick={form.submit(onSubmit)}
            fullWidth
          >
            {t('NEXT')}
          </Button>
        </Grid>
      </Grid>
      {showAddPersonModal && (
        <Grid>
          <AddUsersModal
            onClose={() => setShowAddPersonModal(false)}
            open={showAddPersonModal}
            title={t('ADD_PERSON_MODEL_TITLE')}
            isDynamicClass={true}
          >
            <AddPerson
              setShowAddPersonModal={setShowAddPersonModal}
              refetchPerson={refetchPerson}
              setRecentlyAddedPerson={setRecentlyAddedPerson}
              mainPersonEmail={mainPersonEmail}
              loggedInPerson={loggedInPersonId}
              loggedInPersonName={loggedInPersonName}
            />
          </AddUsersModal>
        </Grid>
      )}
      {showRemovePersonDialog && (
        <Dialog
          open={showRemovePersonDialog}
          onClose={() => setShowRemovePersonDialog(false)}
          scroll={'body'}
          aria-labelledby="delete-person-modal"
          aria-describedby="delete-person-modal"
          className={classes.removePersonDataModalWrapper}
          disableScrollLock
        >
          <Paper className={classes.removePersonDataModal}>
            <Box>
              <Grid container justify="space-between">
                <Typography
                  variant={'body1'}
                  gutterBottom
                  style={{ paddingRight: '24px' }}
                >
                  {''}
                </Typography>

                <IconButton
                  aria-label="close"
                  color="inherit"
                  onClick={() => setShowRemovePersonDialog(false)}
                  className={classes.closeBtn}
                >
                  <Close />
                </IconButton>
              </Grid>

              <Box className={classes.modalBody}>
                <Typography variant={'h3'}>
                  {richT('REMOVE_PERSON_CONFIRMATION_TEXT', {
                    PERSON_NAME: selectedPerson.name ?? '',
                    ACCOUNT_NUMBER: accountParams?.accountNumber,
                  })}
                </Typography>

                <Grid style={{ marginTop: 20 }} container spacing={2}>
                  <Grid className={classes.dialogBtn} item xs={12} md={6}>
                    <PGEButton
                      size="large"
                      color="secondary"
                      variant="outlined"
                      onClick={() => setShowRemovePersonDialog(false)}
                    >
                      {t('CANCEL')}
                    </PGEButton>
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.confirmBtn}>
                    <PGEButton
                      size="large"
                      onClick={async () => {
                        handleDeletePerson();
                        setShowRemovePersonDialog(false);
                      }}
                    >
                      {t('CONFIRM')}
                    </PGEButton>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Paper>
        </Dialog>
      )}
    </Grid>
  );
}
