import React, { useContext } from 'react';
import {
  PeakTimeEventSeason,
  PeakTimeRebateCardType,
} from '../../../../__generated__/pge-types';
import { useTranslation } from '../../../../hooks/useTranslation';
import { PeakTimeRebates as TPeakTimeRebates } from '../../types';
import { Card } from '../Card';
import routes from '../../../../routes';
import {
  formatFullDateWithYear,
  formatFullMonthWithDay,
} from '../../../../util/date-utils';
import { LanguageContext } from '../../../../providers/LanguageProvider';
import SeasonalInfo from './SeasonalInfo';
import { toSentenceCase } from '../../../../util/format';
import {
  Box,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import TrophyIcon from '../../../../images/TrophyIcon.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    learnMoreLink: {
      display: 'block',
      textDecoration: 'none',
      fontSize: '14px',
      marginBottom: theme.spacing(1),
    },
    content: {
      fontSize: theme.spacing(1.75),
      marginBottom: theme.spacing(2),
    },
    container: {
      display: 'flex',
    },
    innerContainer: {
      marginLeft: '0.7rem',
    },
  }),
);

export function PeakTimeRebates(props: TPeakTimeRebates) {
  const {
    cardType,
    activePTRSeason,
    lastPTRSeason,
    nextPTRSeason,
    peakTimeEvents,
    seasonalDates,
    totalEarnedCredit,
  } = props;
  const { t, inlineRichT } = useTranslation();
  const { language } = useContext(LanguageContext);

  function getSeasonTranslatedText(season?: PeakTimeEventSeason) {
    if (season === PeakTimeEventSeason.Summer) {
      return t('SUMMER_SEASON');
    }
    if (season === PeakTimeEventSeason.Winter) {
      return t('WINTER_SEASON');
    }
  }
  function getStartEndDates(season: string) {
    let start;
    let end;
    if (season === 'summer') {
      start = seasonalDates?.summer?.start;
      end = seasonalDates?.summer?.end;
    } else {
      start = seasonalDates?.winter?.start;
      end = seasonalDates?.winter?.end;
    }
    return { start, end };
  }

  function renderOffSeasonEarnedCreditContent() {
    const classes = useStyles();
    const season = PeakTimeEventSeason[
      nextPTRSeason || PeakTimeEventSeason.Summer
    ]?.toLowerCase();
    const { start } = getStartEndDates(season);

    return (
      <>
        <Box className={classes.container}>
          <div>
            <img alt={''} src={TrophyIcon} />
          </div>
          <div className={classes.innerContainer}>
            <Typography className={classes.content}>
              {inlineRichT('PTR_OFF_SEASON_TEXT1', {
                AMOUNT: (totalEarnedCredit || 0).toString(),
                PRIOR_SEASON: getSeasonTranslatedText(lastPTRSeason),
              })}
            </Typography>
            <a href={routes.PTR_PLANNING_PEAK_TIME_EVENTS} target="_blank">
              <Typography className={classes.learnMoreLink}>
                {t('LEARN_MORE')}
              </Typography>
            </a>
            <Typography className={classes.content}>
              {inlineRichT('PTR_OFF_SEASON_TEXT2', {
                START_DATE: formatFullMonthWithDay(
                  start ? new Date(start) : new Date(),
                  language,
                  t,
                ),
              })}
            </Typography>
          </div>
        </Box>
      </>
    );
  }

  if (cardType === PeakTimeRebateCardType.OffSeasonEarnedCredit) {
    return (
      <Card
        type="full"
        theme="blue"
        title={t('PEAK_TIME_REBATES')}
        sectionA={renderOffSeasonEarnedCreditContent()}
        sectionB={
          <SeasonalInfo
            season={lastPTRSeason || PeakTimeEventSeason.Summer}
            currentSeasonHeaderText={inlineRichT(
              'PTR_OFF_SEASON_WHITE_BOX_HEADER',
              {
                SEASON: toSentenceCase(
                  getSeasonTranslatedText(lastPTRSeason) || '',
                ),
              },
            )}
            peakTimeEvents={peakTimeEvents}
          />
        }
      />
    );
  }

  if (cardType === PeakTimeRebateCardType.EnrollmentAfterMostRecentSeason) {
    const season = PeakTimeEventSeason[
      nextPTRSeason || PeakTimeEventSeason.Summer
    ]?.toLowerCase();
    const { start, end } = getStartEndDates(season);

    return (
      <Card
        type="full"
        theme="blue"
        title={t('PEAK_TIME_REBATES')}
        sectionA={inlineRichT('PTR_NEXT_SEASON_DATES_BLUE_CARD_CONTENT', {
          NEXT_SEASON_START_DATE: formatFullMonthWithDay(
            start ? new Date(start) : new Date(),
            language,
            t,
          ),
        })}
        isCtaExternal={true}
        ctaText={t('LEARN_MORE')}
        ctaLink={routes.PTR_PLANNING_PEAK_TIME_EVENTS}
        sectionB={
          <SeasonalInfo
            season={nextPTRSeason || PeakTimeEventSeason.Summer}
            currentSeasonHeaderText={inlineRichT(
              'PTR_NEXT_SEASON_DATES_WHITE_CARD_HEADER',
              {
                START_DATE: formatFullMonthWithDay(
                  start ? new Date(start) : new Date(),
                  language,
                  t,
                ),
              },
            )}
            currentSeasonInfo={inlineRichT('PTR_NEXT_SEASON_FROM_TO_DATES', {
              SEASON: getSeasonTranslatedText(nextPTRSeason),
              START_DATE: formatFullDateWithYear(
                start ? new Date(start) : new Date(),
                language,
              ),
              END_DATE: formatFullDateWithYear(
                end ? new Date(end) : new Date(),
                language,
              ),
            })}
          />
        }
      />
    );
  }

  if (cardType === PeakTimeRebateCardType.OffSeasonNoCreditEarned) {
    const season = PeakTimeEventSeason[
      nextPTRSeason || PeakTimeEventSeason.Summer
    ]?.toLowerCase();
    const { start } = getStartEndDates(season);
    return (
      <Card
        type="full"
        theme="blue"
        title={t('PEAK_TIME_REBATES')}
        sectionA={t('PTR_OFF_SEASON_NOT_EARNED_CONTENT')}
        isCtaExternal={true}
        ctaText={t('LEARN_MORE')}
        ctaLink={routes.PTR_PLANNING_PEAK_TIME_EVENTS}
        sectionB={
          <SeasonalInfo
            season={nextPTRSeason || PeakTimeEventSeason.Summer}
            currentSeasonHeaderText={inlineRichT(
              'PTR_NEXT_SEASON_DATES_WHITE_CARD_HEADER',
              {
                START_DATE: formatFullMonthWithDay(
                  start ? new Date(start) : new Date(),
                  language,
                  t,
                ),
              },
            )}
            currentSeasonInfo={inlineRichT('PTR_NEXT_SEASON_START_DATE', {
              START_DATE: formatFullMonthWithDay(
                start ? new Date(start) : new Date(),
                language,
                t,
              ),
            })}
          />
        }
      />
    );
  }

  if (cardType === PeakTimeRebateCardType.OffSeasonMinimalCreditEarned) {
    const season = PeakTimeEventSeason[
      nextPTRSeason || PeakTimeEventSeason.Summer
    ]?.toLowerCase();
    const { start } = getStartEndDates(season);
    return (
      <Card
        type="full"
        theme="blue"
        title={t('PEAK_TIME_REBATES')}
        sectionA={t('PTR_MINIMAL_REWARDS_EARNED_CONTENT')}
        isCtaExternal={true}
        ctaText={t('LEARN_MORE')}
        ctaLink={routes.PTR_PLANNING_PEAK_TIME_EVENTS}
        sectionB={
          <SeasonalInfo
            season={nextPTRSeason || PeakTimeEventSeason.Summer}
            currentSeasonHeaderText={inlineRichT(
              'PTR_NEXT_SEASON_DATES_WHITE_CARD_HEADER',
              {
                START_DATE: formatFullMonthWithDay(
                  start ? new Date(start) : new Date(),
                  language,
                  t,
                ),
              },
            )}
            currentSeasonInfo={inlineRichT('PTR_NEXT_SEASON_START_DATE', {
              START_DATE: formatFullMonthWithDay(
                start ? new Date(start) : new Date(),
                language,
                t,
              ),
            })}
          />
        }
      />
    );
  }

  if (cardType === PeakTimeRebateCardType.PriorToSummerSeason) {
    return (
      <Card
        type="full"
        theme="blue"
        title={inlineRichT('PTR_PRIOR_TO_SEASON_START_HEADER')}
        sectionA={t('PTR_PRIOR_TO_SUMMER_START_CONTENT')}
        isCtaExternal={true}
        ctaText={t('LEARN_MORE')}
        ctaLink={routes.PTR_PLANNING_PEAK_TIME_EVENTS}
        sectionB={
          <SeasonalInfo
            season={PeakTimeEventSeason.Summer}
            currentSeasonHeaderText={inlineRichT('PTR_PRIOR_TO_SUMMER_START')}
          />
        }
      />
    );
  }

  if (cardType === PeakTimeRebateCardType.ActiveSeasonEarnedCredit) {
    return (
      <Card
        type="full"
        theme="blue"
        title={inlineRichT('PTR_ON_GOING_EVENTS_HEADER', {
          CURRENT_SEASON: toSentenceCase(
            getSeasonTranslatedText(activePTRSeason) || '',
          ),
        })}
        sectionA={t('PTR_ON_GOING_EVENTS_CONTENT')}
        isCtaExternal={true}
        ctaText={t('LEARN_MORE')}
        ctaLink={routes.PTR_PLANNING_PEAK_TIME_EVENTS}
        sectionB={
          <SeasonalInfo
            season={activePTRSeason || PeakTimeEventSeason.Summer}
            currentSeasonHeaderText={inlineRichT('CURRENT_SEASON_ACTIVE', {
              SEASON: toSentenceCase(
                getSeasonTranslatedText(activePTRSeason) || '',
              ),
            })}
            peakTimeEvents={peakTimeEvents}
          />
        }
      />
    );
  }

  if (cardType === PeakTimeRebateCardType.ActiveSeasonNoCreditEarned) {
    return (
      <Card
        type="full"
        theme="blue"
        title={inlineRichT('PTR_ON_GOING_EVENTS_HEADER', {
          CURRENT_SEASON: getSeasonTranslatedText(activePTRSeason),
        })}
        sectionA={t('PTR_ON_GOING_EVENTS_CONTENT')}
        isCtaExternal={true}
        ctaText={t('LEARN_MORE')}
        ctaLink={routes.PTR_PLANNING_PEAK_TIME_EVENTS}
        sectionB={
          <SeasonalInfo
            season={activePTRSeason || PeakTimeEventSeason.Summer}
            currentSeasonInfo={t('STAY_TUNED_UPCOMING_EVENTS')}
            currentSeasonHeaderText={inlineRichT('SEASON_UNDERWAY', {
              SEASON: toSentenceCase(
                getSeasonTranslatedText(activePTRSeason) || '',
              ),
            })}
          />
        }
      />
    );
  }

  return <></>;
}
