import { useContext } from 'react';
import {
  AccountDetail,
  CommunitySolarDetails,
  LastSeason,
  Maybe,
  NetMeteringDetails,
  PeakTimeEnrollmentStatus,
  PeakTimeEvent,
  PeakTimeRebateEnrollmentDetails,
  PremiseInfo,
  ProgramsEnrollmentStatusDetails,
  RenewablesEnrollmentDetails,
  SmartBatteryDetails,
  SmartChargingEnrollmentDetails,
  SmartChargingEnrollmentStatus,
  SmartThermostatEnrollmentDetails,
  TimeOfDayCardType,
  TimeOfDayEnrollmentDetails,
} from '../../__generated__/pge-types';
import useAuthQuery from '../../hooks/useAuthQuery';
import useSelectedAccountParams from '../../hooks/useSelectedAccountParams';
import { LanguageContext } from '../../providers/LanguageProvider';
import { formatDateString } from '../../util/date-utils';
import {
  GET_ACCOUNT_DETAILS,
  GET_COMMUNITY_SOLAR_DETAILS,
  GET_NET_METERING_DETAILS,
  GET_RENEWABLES_ENROLLMENT_DETAILS,
  GET_SMART_BATTERY_DETAILS,
  getPeakTimeRebateEnrollmentDetails,
  getProgramsEnrollmentStatusDetailsQuery,
  getSmartChargingEnrollmentDetailsQuery,
  getSmartThermostatEnrollmentDetailsQuery,
  getTimeOfDayEnrollmentDetailsQuery,
} from './queries';
import { TEnergyShiftCards, TRenewablesCards } from './types';
import { getActiveEncryptedPremiseId, getActiveEncryptedSaId } from './utils';
import { useLocation } from '@reach/router';

export function useMyPrograms() {
  const energyShiftCardsData: TEnergyShiftCards = {
    smartThermostat: {
      cardType: 'unknown',
      lastSeason: LastSeason.Last,
    },
    smartCharging: {
      cardType: 'unknown',
    },
    peakTimeRebates: {
      cardType: 'unknown',
    },
    timeOfDay: {
      cardType: 'unknown',
    },
    smartBattery: {
      cardType: 'unknown',
    },
  };
  const renewablesCardsData: TRenewablesCards = {
    habitatSupport: {
      isEnrolled: 'unknown',
    },
    greenFuture: {
      cardType: 'unknown',
    },
    netMetering: {
      cardType: 'unknown',
    },
    communitySolar: {
      cardType: 'unknown',
    },
  };

  const { language } = useContext(LanguageContext);
  const { accountParams, encryptedAccountNumber } = useSelectedAccountParams();
  const { search } = useLocation();

  // Common
  const {
    data: account,
    loading: accountsLoading,
    error: getAccountsError,
  } = useAuthQuery<{
    getAccountDetails: [AccountDetail];
  }>(GET_ACCOUNT_DETAILS, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    skip: !accountParams,
    errorPolicy: 'all',
  });

  const encryptedServiceAgreementId = getActiveEncryptedSaId(
    account?.getAccountDetails?.[0]?.premiseInfo as PremiseInfo[] | undefined,
  );

  const encryptedPremiseId = getActiveEncryptedPremiseId(
    account?.getAccountDetails?.[0]?.premiseInfo as PremiseInfo[] | undefined,
  );

  // Green Energy And Habitat Support
  const {
    data: renewablesCardsResponse,
    loading: renewablesCardsResponseLoading,
    error: renewablesCardsResponseError,
  } = useAuthQuery<{
    getRenewablesEnrollmentDetails: Maybe<RenewablesEnrollmentDetails>;
  }>(GET_RENEWABLES_ENROLLMENT_DETAILS, {
    variables: {
      params: {
        encryptedServiceAgreementId,
      },
    },
    skip: !encryptedServiceAgreementId,
    errorPolicy: 'all',
  });

  const greenFutureProgramDetails =
    renewablesCardsResponse?.getRenewablesEnrollmentDetails
      ?.greenFutureProgramDetails;
  if (
    greenFutureProgramDetails?.isEnrolled &&
    greenFutureProgramDetails?.cardType
  ) {
    renewablesCardsData.greenFuture.cardType =
      greenFutureProgramDetails.cardType;
    renewablesCardsData.greenFuture.kwh =
      greenFutureProgramDetails.totalConsumption ?? undefined;
    renewablesCardsData.greenFuture.consumptionPercentage =
      greenFutureProgramDetails.consumptionPercentage ?? undefined;
  }

  if (renewablesCardsResponse?.getRenewablesEnrollmentDetails?.habitatSupport) {
    if (
      renewablesCardsResponse?.getRenewablesEnrollmentDetails?.habitatSupport
        ?.isEnrolled
    ) {
      renewablesCardsData.habitatSupport.isEnrolled = true;
    } else {
      renewablesCardsData.habitatSupport.isEnrolled = false;
    }
  }

  // Net Metering
  const {
    data: netMeteringCardResponse,
    loading: netMeteringCardResponseLoading,
    error: netMeteringCardResponseError,
  } = useAuthQuery<{
    getNetMeteringDetails?: Maybe<NetMeteringDetails>;
  }>(GET_NET_METERING_DETAILS, {
    variables: {
      params: {
        encryptedAccountId: encryptedAccountNumber,
        encryptedPremiseId,
      },
    },
    skip: !accountParams || !encryptedPremiseId,
    errorPolicy: 'all',
  });

  const netMeteringDetails = netMeteringCardResponse?.getNetMeteringDetails;
  if (netMeteringDetails?.isFirstBillGenerated) {
    renewablesCardsData.netMetering.cardType = 'NET_METERING_BALANCE';
    renewablesCardsData.netMetering.monthlyBill = {
      consumption: netMeteringDetails.monthlyBill?.consumption,
      excessGeneration: netMeteringDetails.monthlyBill?.excessGeneration,
      billedEnergyThisMonth:
        netMeteringDetails.monthlyBill?.billedEnergyThisMonth,
      energySavingBalance: netMeteringDetails.monthlyBill?.energySavingBalance,
      energySavingAccountBalance:
        netMeteringDetails.monthlyBill?.energySavingAccountBalance,
    };
  } else if (
    !netMeteringDetails?.isFirstBillGenerated &&
    netMeteringDetails?.application
  ) {
    renewablesCardsData.netMetering.cardType =
      netMeteringDetails.application.status ?? 'unknown';
    renewablesCardsData.netMetering.date = formatDateString(
      netMeteringDetails?.application?.submitDate,
    );
  }

  //All programs enrollement status details
  const {
    data: enrollmentStatusResponse,
    loading: isEnrollementStatusLoading,
    error: enrollementStatusError,
  } = useAuthQuery<{
    getProgramsEnrollmentStatusDetails: ProgramsEnrollmentStatusDetails;
  }>(getProgramsEnrollmentStatusDetailsQuery, {
    variables: {
      params: {
        encryptedAccountNumber,
        encryptedPremiseId,
        encryptedSaId: encryptedServiceAgreementId,
      },
    },
    skip:
      !encryptedAccountNumber ||
      !encryptedPremiseId ||
      !encryptedServiceAgreementId,
  });

  const ytdFlexLoadEarnings =
    enrollmentStatusResponse?.getProgramsEnrollmentStatusDetails
      ?.ytdFlexLoadEarnings;

  // PTR
  const {
    data: ptrResponse,
    loading: ptrLoading,
    error: ptrError,
  } = useAuthQuery<{
    getPeakTimeRebateEnrollmentDetails: PeakTimeRebateEnrollmentDetails;
  }>(getPeakTimeRebateEnrollmentDetails, {
    variables: {
      params: {
        encryptedAccountNumber,
        ptrMockServerDate: search?.split('=')?.[1] || '',
      },
    },
    skip: !encryptedAccountNumber,
  });

  const ptr = ptrResponse?.getPeakTimeRebateEnrollmentDetails;
  if (ptr && ptr.enrollmentStatus === PeakTimeEnrollmentStatus.Enrolled) {
    energyShiftCardsData.peakTimeRebates.cardType = ptr.cardType ?? 'unknown';
    energyShiftCardsData.peakTimeRebates.enrollmentStatus =
      ptr.enrollmentStatus;
    energyShiftCardsData.peakTimeRebates.seasonalDates =
      ptr.seasonalDates ?? undefined;
    energyShiftCardsData.peakTimeRebates.activePTRSeason =
      ptr.activePTRSeason ?? undefined;
    energyShiftCardsData.peakTimeRebates.lastPTRSeason =
      ptr.lastPTRSeason ?? undefined;
    energyShiftCardsData.peakTimeRebates.nextPTRSeason =
      ptr.nextPTRSeason ?? undefined;
    energyShiftCardsData.peakTimeRebates.totalEarnedCredit =
      ptr.totalEarnedCredit ?? undefined;
    if ((ptr.peakTimeEvents?.length ?? 0) > 15) {
      energyShiftCardsData.peakTimeRebates.peakTimeEvents =
        (ptr.peakTimeEvents?.slice(15) as PeakTimeEvent[]) ?? undefined;
    } else {
      energyShiftCardsData.peakTimeRebates.peakTimeEvents =
        (ptr.peakTimeEvents as PeakTimeEvent[]) ?? undefined;
    }
  }

  // Smart Thermostat
  const {
    data: smartThermostateEnrollmentDetails,
    loading: smartThermostateEnrollmentLoading,
    error: smartThermostateEnrollmentError,
  } = useAuthQuery<{
    getSmartThermostatEnrollmentDetails: SmartThermostatEnrollmentDetails;
  }>(getSmartThermostatEnrollmentDetailsQuery, {
    variables: {
      params: {
        encryptedAccountNumber,
      },
    },
    skip: !encryptedAccountNumber,
    errorPolicy: 'all',
  });

  const smartThermostat =
    smartThermostateEnrollmentDetails?.getSmartThermostatEnrollmentDetails;

  if (smartThermostat?.isEnrolled) {
    energyShiftCardsData.smartThermostat.cardType =
      smartThermostat.cardType ?? 'unknown';
    energyShiftCardsData.smartThermostat.lastSeason =
      smartThermostat.lastSeason ?? LastSeason.Last;
    energyShiftCardsData.smartThermostat.lastSeasonEarnedCredit =
      smartThermostat.lastSeasonEarnedCredit ?? undefined;
    energyShiftCardsData.smartThermostat.seasonalDates =
      smartThermostat.seasonalDates ?? undefined;
  }

  // Smart Charging
  const {
    data: smartChargingData,
    loading: smartChargingLoading,
    error: smartChargingError,
  } = useAuthQuery<{
    getSmartChargingEnrollmentDetails: SmartChargingEnrollmentDetails;
  }>(getSmartChargingEnrollmentDetailsQuery, {
    variables: {
      params: {
        encryptedAccountNumber,
      },
    },
    skip: !encryptedAccountNumber,
  });

  const smartCharging = smartChargingData?.getSmartChargingEnrollmentDetails;

  if (
    smartCharging &&
    smartCharging.enrollmentStatus === SmartChargingEnrollmentStatus.Enrolled
  ) {
    energyShiftCardsData.smartCharging.cardType =
      smartCharging.cardType ?? 'unknown';
    energyShiftCardsData.smartCharging.activeSeason =
      smartCharging.activeSeason ?? undefined;
    energyShiftCardsData.smartCharging.enrollmentStatus =
      smartCharging.enrollmentStatus ?? undefined;
    energyShiftCardsData.smartCharging.lastSeasonEarnedCredit =
      smartCharging.lastSeasonEarnedCredit ?? undefined;
  }

  //Time of Day
  const {
    data: timeOfDayData,
    loading: timeOfDayLoading,
    error: timeOfDayError,
  } = useAuthQuery<{
    getTimeOfDayEnrollmentDetails: TimeOfDayEnrollmentDetails;
  }>(getTimeOfDayEnrollmentDetailsQuery, {
    variables: {
      params: {
        encryptedServiceAgreementId,
        encryptedAccountNumber,
      },
    },
    skip: !encryptedServiceAgreementId || !encryptedAccountNumber,
  });

  if (timeOfDayData) {
    const tod = timeOfDayData.getTimeOfDayEnrollmentDetails;
    if (tod) {
      energyShiftCardsData.timeOfDay.cardType =
        tod.cardType === TimeOfDayCardType.None ? 'unknown' : tod.cardType;
      energyShiftCardsData.timeOfDay.planSavings = tod.planSavings ?? undefined;
      energyShiftCardsData.timeOfDay.isEnrolled = tod.isEnrolled;
      energyShiftCardsData.timeOfDay.offPeakCharges =
        tod.offPeakCharges ?? undefined;
      energyShiftCardsData.timeOfDay.onPeakCharges =
        tod.onPeakCharges ?? undefined;
      energyShiftCardsData.timeOfDay.midPeakCharges =
        tod.midPeakCharges ?? undefined;
      energyShiftCardsData.timeOfDay.annualLookBackEarnedCredit =
        tod.annualLookBackEarnedCredit ?? undefined;
    }
  }

  // Community Solar
  const {
    data: communitySolarCardResponse,
    loading: communitySolarCardResponseLoading,
    error: communitySolarCardResponseError,
  } = useAuthQuery<{
    getCommunitySolarDetails?: Maybe<CommunitySolarDetails>;
  }>(GET_COMMUNITY_SOLAR_DETAILS, {
    variables: {
      params: {
        encryptedAccountId: encryptedAccountNumber,
      },
    },
    skip: !encryptedAccountNumber,
    errorPolicy: 'all',
  });

  const communitySolarDetails =
    communitySolarCardResponse?.getCommunitySolarDetails;
  if (communitySolarDetails?.isEnrolled) {
    renewablesCardsData.communitySolar.cardType =
      communitySolarDetails.cardType ?? 'unknown';
    renewablesCardsData.communitySolar.amountDetails =
      communitySolarDetails.amountDetails ?? undefined;
    if ((communitySolarDetails.amountDetails?.currentMonths?.length ?? 0) > 2) {
      renewablesCardsData.communitySolar.amountDetails!.currentMonths = undefined;
    }
    renewablesCardsData.communitySolar.chargesAndCredits =
      communitySolarDetails.chargesAndCredits ?? undefined;
  }

  // Smart Battery
  const {
    data: smartBatteryCardResponse,
    loading: smartBatteryCardResponseLoading,
    error: smartBatteryCardResponseError,
  } = useAuthQuery<{ getSmartBatteryDetails: Maybe<SmartBatteryDetails> }>(
    GET_SMART_BATTERY_DETAILS,
    {
      variables: {
        params: {
          encryptedServiceAgreementId,
        },
      },
      skip: !encryptedServiceAgreementId,
      errorPolicy: 'all',
    },
  );

  const smartBattery = smartBatteryCardResponse?.getSmartBatteryDetails;
  if (smartBattery?.isEnrolled) {
    energyShiftCardsData.smartBattery.cardType =
      smartBattery?.cardType ?? 'unknown';
    energyShiftCardsData.smartBattery.currentBillCreditAmount =
      smartBattery.currentBillCreditAmount ?? undefined;
    energyShiftCardsData.smartBattery.currentBillKwh =
      smartBattery.currentBillKwh ?? undefined;
    energyShiftCardsData.smartBattery.peakTimeSeason =
      smartBattery.peakTimeSeason ?? undefined;
    energyShiftCardsData.smartBattery.ytdCreditAmount =
      smartBattery.ytdCreditAmount ?? undefined;
    energyShiftCardsData.smartBattery.ytdKwh = smartBattery.ytdKwh ?? undefined;
  }

  // Loaders And Errors
  const energyShiftCardsLoading =
    ptrLoading ||
    smartThermostateEnrollmentLoading ||
    timeOfDayLoading ||
    smartChargingLoading ||
    smartBatteryCardResponseLoading;

  const energyShiftCardsError =
    ptrError ||
    smartThermostateEnrollmentError ||
    timeOfDayError ||
    smartChargingError ||
    smartBatteryCardResponseError;

  const renewablesCardsLoading =
    accountsLoading ||
    renewablesCardsResponseLoading ||
    netMeteringCardResponseLoading ||
    communitySolarCardResponseLoading;

  const renewablesCardsError =
    getAccountsError ||
    renewablesCardsResponseError ||
    netMeteringCardResponseError ||
    communitySolarCardResponseError;

  return {
    energyShiftCardsData,
    energyShiftCardsLoading,
    energyShiftCardsError,
    renewablesCardsData,
    renewablesCardsLoading,
    renewablesCardsError,
    isEnrollementStatusLoading,
    ytdFlexLoadEarnings,
    language,
  };
}
