import { Box, Typography, createStyles, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import { SeasonalDates as TSeasonalDates } from '../../../../__generated__/pge-types';
import { useTranslation } from '../../../../hooks/useTranslation';
import theme from '../../../../themes/theme';
import IconSnow from '../../../../static/snowIcon.svg';
import IconSun from '../../../../static/sunIcon.svg';
import { formatDateToLongFormat } from '../../../../util/date-utils';
import { LanguageContext } from '../../../../providers/LanguageProvider';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      background: 'white',
      padding: theme.spacing(2),
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      gap: '10px',
      paddingBottom: theme.spacing(1),
    },
    seasonText: {
      '& p': {
        margin: 0
      }
    },
    icon: {
      width:'50px'
    }
  })
);

export function SeasonalDates({ season }: { season?: TSeasonalDates }) {
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const { language } = useContext(LanguageContext);
  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <IconSun className={classes.icon}/>
        <Typography
          variant="body2"
          data-testid={'summer-text'}
          className={classes.seasonText}
        >
          {richT('SUMMER_SEASON_TEXT', {
            SEASON_START: formatDateToLongFormat(
              new Date(season?.summer?.start!),
              language,
              t,
            ),
            SEASON_END: formatDateToLongFormat(
              new Date(season?.summer?.end!),
              language,
              t,
            ),
          })}
        </Typography>
      </Box>

      <Box className={classes.content}>
        <IconSnow className={classes.icon}/>
        <Typography
          variant="body2"
          data-testid={'winter-text'}
          className={classes.seasonText}
        >
          {richT('WINTER_SEASON_TEXT', {
            SEASON_START: formatDateToLongFormat(
              new Date(season?.winter?.start!),
              language,
              t,
            ),
            SEASON_END: formatDateToLongFormat(
              new Date(season?.winter?.end!),
              language,
              t,
            ),
          })}
        </Typography>
      </Box>
    </Box>
  );
}
