import gql from 'graphql-tag';
import {
  AccountDetail,
  AccountParams,
  EnergyTrackerData,
  EnergyTrackerDetails,
  Maybe,
  NotificationFor,
  NotificationType,
} from '../../../../__generated__/pge-types';
import useAuthQuery from '../../../../hooks/useAuthQuery';
import useGetManageAlertsService from '../../../../hooks/useGetManageAlerts';

type DialInfo = {
  angle: number;
  thresholdAngle: number;
  p1: number;
  x: number;
  y: number;
  height: string;
  marginLeft: string;
  marginBottom: string;
  containerBottomMargin: string;
};

export const stripOffCents = (value: number | undefined | null): number => {
  return value ? Math.floor(value) : 0;
};

const getMonthlyUsageComparison = (
  currentUsage: number,
  previousUsage: number,
) => {
  const usageDetails: {
    currentUsePercentage: number;
    currentUseIndicator: 'more' | 'less' | 'same';
  } = {
    currentUsePercentage: 0,
    currentUseIndicator: 'same',
  };

  if (currentUsage === 0 || previousUsage === 0) {
    usageDetails.currentUseIndicator = 'less';
  } else if (currentUsage < previousUsage) {
    usageDetails.currentUsePercentage = Math.round(
      (1 - currentUsage / previousUsage) * 100,
    );
    usageDetails.currentUseIndicator = 'less';
  } else if (currentUsage > previousUsage) {
    usageDetails.currentUsePercentage = Math.round(
      (currentUsage / previousUsage - 1) * 100,
    );
    usageDetails.currentUseIndicator = 'more';
  }

  return usageDetails;
};

const getUsageInfo = gql`
  query getAccountDetails($params: AccountDetailParams!) {
    getAccountDetails(params: $params) {
      viewBillAverageTemperature {
        currentBillingPeriod {
          totalKwh
        }
        previousBillingPeriod {
          totalKwh
        }
      }
    }
  }
`;

export const useCurrentUseCard = (
  energyTrackerData:
    | {
        getEnergyTrackerData: EnergyTrackerData;
      }
    | undefined,
  energyTrackerLoading: boolean,
  energyTrackerError: boolean,
  accountParams: AccountParams | undefined,
) => {
  const {
    data: usageData,
    loading: usageLoading,
    error: usageError,
  } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getUsageInfo, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    errorPolicy: 'all',
    skip: !accountParams,
  });

  const { data: alertData, loading: alertsLoading } = useGetManageAlertsService(
    {
      requestParams: {
        params: {
          acctId: accountParams?.accountNumber,
          encryptedPersonId: accountParams?.encryptedPersonId,
          commPreferencesParams: {
            notificationFor: NotificationFor.Alert,
            notificationTypes: [NotificationType.Webexc],
          },
        },
        skipParam: false,
      },
    },
  );

  const energyTrackerDetails: Maybe<EnergyTrackerDetails> | undefined =
    energyTrackerData?.getEnergyTrackerData?.details;

  const webExecNotif = alertData?.getAlerts?.alerts?.find(
    alert => alert?.notificationType === NotificationType.Webexc,
  );

  const displayThreshold =
    webExecNotif?.deliveryTypeDetails?.[0]?.contactDetails?.[0]?.isSelected ||
    webExecNotif?.deliveryTypeDetails?.[1]?.contactDetails?.[0]?.isSelected ||
    false;

  const currentUsage =
    usageData?.getAccountDetails?.[0]?.viewBillAverageTemperature
      ?.currentBillingPeriod?.totalKwh || 0;
  const previousUsage =
    usageData?.getAccountDetails?.[0]?.viewBillAverageTemperature
      ?.previousBillingPeriod?.totalKwh || 0;

  const dialReading =
    ((energyTrackerDetails?.billToDateAmount || 0) * 100) /
    (energyTrackerDetails?.maxProjectedAmount || 1);
  const dialAngle = dialReading < 100 ? (dialReading / 100) * 180 : 180;
  const thresholdReading =
    (Number(webExecNotif?.notificationOptions?.value || 0) * 100) /
    (energyTrackerDetails?.maxProjectedAmount || 1);
  const thresholdAngle =
    thresholdReading < 100 ? (thresholdReading / 100) * 180 : 180;
  const getDialInfo = (): DialInfo => {
    if (thresholdAngle > 0 && thresholdAngle < 30) {
      return {
        angle: dialAngle,
        thresholdAngle,
        p1: 0,
        x: 80,
        y: 72,
        height: '130px',
        marginLeft: '-15px',
        marginBottom: 'unset',
        containerBottomMargin: 'unset',
      };
    }
    if (thresholdAngle > 30 && thresholdAngle < 52) {
      return {
        angle: dialAngle,
        thresholdAngle,
        p1: -20,
        x: 120,
        y: 72,
        height: '175px',
        marginLeft: '-15px',
        marginBottom: '30px',
        containerBottomMargin: '-30px',
      };
    }
    if (thresholdAngle > 51 && thresholdAngle < 121) {
      return {
        angle: dialAngle,
        thresholdAngle,
        p1: -20,
        x: 120,
        y: 60,
        height: '175px',
        marginLeft: '-15px',
        marginBottom: '20px',
        containerBottomMargin: '-20px',
      };
    }
    if (thresholdAngle > 122 && thresholdAngle < 156) {
      return {
        angle: dialAngle,
        thresholdAngle,
        p1: 0,
        x: 120,
        y: 72,
        height: '180px',
        marginLeft: '-50px',
        marginBottom: '20px',
        containerBottomMargin: '-30px',
      };
    }
    if (thresholdAngle > 155 && thresholdAngle <= 180) {
      return {
        angle: dialAngle,
        thresholdAngle,
        p1: 0,
        x: 120,
        y: 72,
        height: '130px',
        marginLeft: '-10px',
        marginBottom: '20px',
        containerBottomMargin: '-10px',
      };
    }
    return {
      angle: dialAngle,
      thresholdAngle,
      p1: 0,
      x: 80,
      y: 72,
      height: '130px',
      marginLeft: '-15px',
      marginBottom: 'unset',
      containerBottomMargin: 'unset',
    };
  };

  const currentUseCardInfo: {
    displayThreshold: boolean;
    threshold: number;
    estimatedCurrentCharges: number;
    nextBillLowerBound: number;
    nextBillHigherBound: number;
    billingCycleCurrentDay: number;
    billingCycleTotalDays: number;
    currentUsePercentage: number;
    currentUseIndicator: string;
    dialInfo: DialInfo;
  } = {
    displayThreshold,
    threshold: Number(
      stripOffCents(Number(webExecNotif?.notificationOptions?.value || 0)),
    ),
    estimatedCurrentCharges: stripOffCents(
      energyTrackerDetails?.billToDateAmount,
    ),
    nextBillLowerBound: stripOffCents(energyTrackerDetails?.minProjectedAmount),
    nextBillHigherBound: stripOffCents(
      energyTrackerDetails?.maxProjectedAmount,
    ),
    billingCycleCurrentDay: energyTrackerDetails?.billingCycleDay || 0,
    billingCycleTotalDays: energyTrackerDetails?.numberOfBillingDays || 0,
    dialInfo: getDialInfo(),
    ...getMonthlyUsageComparison(currentUsage, previousUsage),
  };

  const loading = energyTrackerLoading || usageLoading || alertsLoading;
  const error = energyTrackerError || usageError;

  return { currentUseCardInfo, loading, error };
};
