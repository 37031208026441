import {
  useContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import compact from 'lodash/compact';
import isEqual from 'lodash/isEqual';

import useValidationForm from './useValidationForm';
import { useMutation } from '@apollo/react-hooks';
import {
  AccountPerson,
  QueryGetPersonsForAccountArgs,
  Address,
  OnlineAccountType,
  ServiceAddress,
  SubmitUpdateInformationInput,
  CommercialContactInfoInput,
  AccountDetail,
  QuickAddressSearchParams,
  SuggestedAddress,
  AccountCustomer,
  RelationshipType,
  PremiseInfo,
} from '../__generated__/pge-types';
import { NotificationsContext } from '../providers/NotificationsProvider';
import { useTranslation } from './useTranslation';
import useSelectedGroupId from './useSelectedGroupId';
import { getAccountsList } from '../components/account-summary/multi-accounts/queries';
import useFeatureFlagProtectedValues from '../components/account-summary/multi-accounts/useFeatureFlagProtectedValues';
import useAccountDetailList from './useAccountDetailList';
import useSelectedAccountParams from './useSelectedAccountParams';
import useAuthQuery from './useAuthQuery';
import {
  ResidentialFormValidationRules,
  CommercialFormValidationRules,
  isNullOrEmpty,
} from '../components/update-info/UpdateInfoForm.rules';
import {
  getPersonsForAccountQuery,
  getMailingAddressForAccountQuery,
  updateAccountInformationMutation,
} from '../components/update-info/updateInfo.query';
import {
  statesAndProvincesGrouping,
  isCanadianState,
} from '../components/utility/state-dropdown';
import useWrapWithLoader from './useWrapWithLoading';
import useQas, { QasResult } from './useQas';
import { getPostalCountry, parsePhoneNumber } from '../util/format';
import { useUpdateInfoApi } from '../components/update-info/useUpdateInfoApi';
import useAccountCustomer from './useAccountCustomer';
import { PreferredLanguages } from '../util/languageUtil';
export interface DefaultFormValue {
  value: string | number | boolean;
  errorMessage: string | null;
}

function serviceAddressToAddress(
  serviceAddress: ServiceAddress | Address | undefined | null,
): Address | undefined {
  if (!serviceAddress) {
    return undefined;
  }

  return {
    addressLine1: serviceAddress.addressLine1,
    state: serviceAddress.state,
    postal:
      serviceAddress?.postal &&
      serviceAddress?.state &&
      !isCanadianState(serviceAddress?.state)
        ? serviceAddress?.postal.substring(0, 5)
        : serviceAddress?.postal, // sometime the postal address format is like '12445-12345' which is failing the validation rules hence we are picking up first 5 characters of the postal code
    city: serviceAddress.city,
    country: serviceAddress.country,
  };
}

function premiseInfoToServiceAddress(
  premiseInfo: PremiseInfo | undefined | null,
): ServiceAddress | undefined {
  if (!premiseInfo) {
    return undefined;
  }

  return {
    addressLine1: premiseInfo.addressLine1,
    state: premiseInfo.state,
    postal:
      premiseInfo?.postal &&
      premiseInfo?.state &&
      !isCanadianState(premiseInfo?.state)
        ? premiseInfo?.postal.substring(0, 5)
        : premiseInfo?.postal, // sometime the postal address format is like '12445-12345' which is failing the validation rules hence we are picking up first 5 characters of the postal code
    city: premiseInfo.city,
    country: premiseInfo.country,
  };
}

export interface UpdateInfoFormPropsInterface {
  handleSubmit: () => void;
  handleCancel: () => void;
  handleChange: () => void;
  onBlur: (event: any) => void;
  address: DefaultFormValue;
  city: DefaultFormValue;
  state: DefaultFormValue;
  zip: DefaultFormValue;
  primaryPhone: DefaultFormValue;
  mobilePhone: DefaultFormValue;
  alternatePhone: DefaultFormValue;
  name: DefaultFormValue;
  reasonForChange: DefaultFormValue;
  coCustomerName: DefaultFormValue;
  coCustomerReasonForChange: DefaultFormValue;
  contactName: DefaultFormValue;
  contactPrimaryPhone: DefaultFormValue;
  primaryPhoneExt: DefaultFormValue;
  bookKeeperName: DefaultFormValue;
  bookKeeperPhone: DefaultFormValue;
  bookKeeperPhoneExt: DefaultFormValue;
  preferredLanguage: DefaultFormValue;
  email: DefaultFormValue;
}

export interface statesAndProvincesProps {
  unitedStates: string[];
  canada: string[];
}

type QasState = {
  params: QuickAddressSearchParams;
  results: QasResult;
};

export interface UpdateInfoProps {
  loading: boolean;
  isComplete: boolean;
  accountType: OnlineAccountType;
  accountNumber?: string;
  persons?: AccountPerson[];
  mailingAddress?: Address;
  commercialContactInfo?: CommercialContactInfoInput;
  serviceAddress?: ServiceAddress | null;
  pnpPhone?: string;
  mobNumber?: string;
  altNumber?: string;
  preferredLanguage?: string;
  updateInfoFormProps: UpdateInfoFormPropsInterface & DefaultFormValue;
  initialFormState?: UpdateInfoFormPropsInterface & DefaultFormValue;
  ChangeNameReasons: string[];
  preferredLanguages: string[];
  statesAndProvincesGrouping: statesAndProvincesProps;
  handleFormSubmit: () => void;
  isProvinceSelected: boolean;
  qasState: null | QasState;
  setQasState: Dispatch<SetStateAction<null | QasState>>;
  handleQasSubmit: (isVerified: boolean, address?: SuggestedAddress) => void;
  isMobilePhoneSameAsPrimaryPhone: boolean;
  setIsMobilePhoneSameAsPrimaryPhone: Dispatch<SetStateAction<boolean>>;
}

export interface populatePayloadArgInput {
  accountType: OnlineAccountType;
  mailingAddress?: Address;
  isMailingAddressQasVerified?: boolean;
  serviceAddress?: ServiceAddress | null;
  primaryPhone: string;
  mobilePhone: string;
  alternatePhone: string;
  preferredLanguage: string;
  encryptedAccountNumber?: string;
  encryptedPersonId?: string;
  updateForm: any;
  isMobilePhoneSameAsPrimaryPhone: boolean;
  persons: AccountPerson[];
  commercialContactInfo: CommercialContactInfoInput;
  ChangeNameReasons: string[];
}

const validateChanges = (payload: SubmitUpdateInformationInput) => {
  return isEqual(payload.updateInfo, payload.originalUpdateAccountInfo);
};

const populateUpdateInfoPayload = ({
  accountType,
  mailingAddress = {},
  isMailingAddressQasVerified,
  serviceAddress,
  primaryPhone,
  mobilePhone,
  alternatePhone,
  preferredLanguage,
  encryptedAccountNumber,
  encryptedPersonId,
  updateForm,
  isMobilePhoneSameAsPrimaryPhone,
  persons,
  commercialContactInfo,
}: populatePayloadArgInput): SubmitUpdateInformationInput => {
  const mainCustomer = persons.find(
    p => p.personRelationshipType === RelationshipType.MainCustomer,
  );
  const coAppCustomer = persons.find(
    p => p.personRelationshipType === RelationshipType.CoApplicant,
  );
  const payload: SubmitUpdateInformationInput = {
    accountType,
    encryptedAccountNumber,
    encryptedPersonId,
    serviceAddress: {
      addressLine1: serviceAddress?.addressLine1,
      city: serviceAddress?.city,
      state: serviceAddress?.state,
      postal: serviceAddress?.postal,
      country: serviceAddress?.country || '',
    },
  };

  payload.originalUpdateAccountInfo = {
    accountType,
    mailingAddress: {
      addressLine1: mailingAddress.addressLine1,
      city: mailingAddress.city,
      state: mailingAddress.state,
      postal: mailingAddress.postal,
      country: mailingAddress.country || '',
    },
  };

  payload.updateInfo = {
    accountType,
    mailingAddress: isNullOrEmpty(updateForm, [
      'address',
      'zip',
      'city',
      'state',
    ])
      ? undefined
      : {
          addressLine1: updateForm.address.value.trim(),
          city: updateForm.city.value.trim(),
          state: updateForm.state.value,
          postal: updateForm.zip.value,
          country: getPostalCountry(updateForm.zip.value),
          qasVerified: isMailingAddressQasVerified,
        },
  };

  payload.originalUpdateAccountInfo.primaryPhone = primaryPhone.replace(
    /\D/g,
    '',
  );
  payload.updateInfo.primaryPhone = updateForm.primaryPhone.value.replace(
    /\D/g,
    '',
  );

  payload.originalUpdateAccountInfo.prefLanguage = preferredLanguage;
  payload.updateInfo.prefLanguage = updateForm.preferredLanguage.value;
  payload.originalUpdateAccountInfo.mobilePhone = mobilePhone.replace(
    /\D/g,
    '',
  );
  payload.updateInfo.mobilePhone = isMobilePhoneSameAsPrimaryPhone
    ? payload.updateInfo.primaryPhone
    : updateForm.mobilePhone.value.replace(/\D/g, '');

  payload.originalUpdateAccountInfo.alternatePhone = alternatePhone.replace(
    /\D/g,
    '',
  );
  payload.updateInfo.alternatePhone = updateForm.alternatePhone.value.replace(
    /\D/g,
    '',
  );

  payload.originalUpdateAccountInfo.persons = [];
  if (mainCustomer) {
    payload.originalUpdateAccountInfo.persons.push({
      encryptedPersonId: mainCustomer?.encryptedPersonId,
      fullName: mainCustomer?.fullName,
      personRelationshipType: mainCustomer?.personRelationshipType,
    });
  }

  if (coAppCustomer) {
    payload.originalUpdateAccountInfo.persons.push({
      encryptedPersonId: coAppCustomer?.encryptedPersonId,
      fullName: coAppCustomer?.fullName,
      personRelationshipType: coAppCustomer?.personRelationshipType,
    });
  }

  const updatedPersonInfo = [];
  const mainCustomerInfo: any = {
    fullName: updateForm.name.value,
    encryptedPersonId: mainCustomer?.encryptedPersonId,
    personRelationshipType: RelationshipType.MainCustomer,
  };
  if (updateForm.reasonForChange.value) {
    mainCustomerInfo.changeReason = updateForm.reasonForChange.value;
  }

  updatedPersonInfo.push(mainCustomerInfo);

  if (coAppCustomer) {
    const coCustomerInfo: any = {
      fullName: updateForm.coCustomerName?.value || '',
      encryptedPersonId: coAppCustomer?.encryptedPersonId,
      personRelationshipType: RelationshipType.CoApplicant,
    };
    if (updateForm.coCustomerReasonForChange?.value) {
      coCustomerInfo.changeReason = updateForm.coCustomerReasonForChange.value;
    }
    updatedPersonInfo.push(coCustomerInfo);
  }

  payload.updateInfo.persons = updatedPersonInfo;

  payload.originalUpdateAccountInfo.commContactInfo = commercialContactInfo;
  payload.updateInfo.commContactInfo = {
    contactName: updateForm.contactName.value,
    primaryPhone: updateForm.contactPrimaryPhone.value,
    primaryPhoneExt: updateForm.primaryPhoneExt.value,
    bookKeeperName: updateForm.bookKeeperName.value,
    bookKeeperPhone: updateForm.bookKeeperPhone.value,
    bookKeeperPhoneExt: updateForm.bookKeeperPhoneExt.value,
  };
  return payload;
};

const populateFormValues = (
  customer: AccountCustomer | undefined,
  persons: AccountPerson[],
  mailingAddress: Address = {},
  pnpPhone: string,
  mobNumber: string,
  altNumber: string,
  commercialContactInfo?: CommercialContactInfoInput,
  prefLanguage: string = customer?.prefLanguage || '',
) => {
  const mainCustomer = persons.find(
    p => p.personRelationshipType === RelationshipType.MainCustomer,
  );
  const coAppCustomer = persons.find(
    p => p.personRelationshipType === RelationshipType.CoApplicant,
  );
  const defaultFieldValue = { value: '', errorMessage: '' };
  const formFields: any = {
    // mailing address
    address: { value: mailingAddress.addressLine1, errorMessage: '' },
    city: { value: mailingAddress.city, errorMessage: '' },
    state: { value: mailingAddress.state, errorMessage: '' },
    zip: { value: mailingAddress.postal, errorMessage: '' },
  };

  // customer information
  formFields.name = { value: mainCustomer?.fullName, errorMessage: '' };
  formFields.reasonForChange = defaultFieldValue;
  if (coAppCustomer) {
    formFields.coCustomerName = {
      value: coAppCustomer?.fullName,
      errorMessage: '',
    };
    formFields.coCustomerReasonForChange = defaultFieldValue;
  }

  // phone
  formFields.primaryPhone = { value: pnpPhone, errorMessage: '' };
  formFields.mobilePhone = { value: mobNumber, errorMessage: '' };
  formFields.alternatePhone = {
    value: altNumber,
    errorMessage: '',
  };

  //commercial contact info
  formFields.contactName = {
    value: commercialContactInfo?.contactName || '',
    errorMessage: '',
  };
  formFields.contactPrimaryPhone = {
    value: commercialContactInfo?.primaryPhone || '',
    errorMessage: '',
  };
  formFields.primaryPhoneExt = {
    value: commercialContactInfo?.primaryPhoneExt || '',
    errorMessage: '',
  };
  formFields.bookKeeperName = {
    value: commercialContactInfo?.bookKeeperName || '',
    errorMessage: '',
  };
  formFields.bookKeeperPhone = {
    value: commercialContactInfo?.bookKeeperPhone || '',
    errorMessage: '',
  };
  formFields.bookKeeperPhoneExt = {
    value: commercialContactInfo?.bookKeeperPhoneExt || '',
    errorMessage: '',
  };

  formFields.preferredLanguage = {
    value: prefLanguage,
    errorMessage: '',
  };

  formFields.email = {
    value: customer?.email,
    errorMessage: '',
  };

  return formFields;
};

const { t } = useTranslation();
const getPreferredLanguageList = () =>
  Object.keys(PreferredLanguages).map(each => t(each));
export const preferredLanguages = getPreferredLanguageList();

// eslint-disable-next-line no-redeclare
export const UpdateInfoProps = (): UpdateInfoProps => {
  const ChangeNameReasons = [
    t('MARRIAGE'),
    t('DIVORCE'),
    t('LEGAL'),
    t('CORRECTION'),
  ];
  const { fetchContactDetails } = useUpdateInfoApi();

  const { wrapWithLoader } = useWrapWithLoader();
  const { search } = useQas();
  const [qasState, setQasState] = useState<null | QasState>(null);

  const notificationContext = useContext(NotificationsContext);
  const {
    getAccountListStateByClosedAcctFlag,
  } = useFeatureFlagProtectedValues();

  const [
    isMobilePhoneSameAsPrimaryPhone,
    setIsMobilePhoneSameAsPrimaryPhone,
  ] = useState(false);
  const [isComplete, setComplete] = useState(false);
  const {
    accountNumber,
    encryptedAccountNumber,
    encryptedPersonId,
    accountParams,
  } = useSelectedAccountParams();

  const { selectedGroupId } = useSelectedGroupId();
  const { loading: loadingAccountList, accounts } = useAccountDetailList(
    getAccountsList,
    selectedGroupId,
    {
      accountListParams: {
        ...getAccountListStateByClosedAcctFlag(),
        query: accountNumber || '',
      },
    },
  );

  const selectedAccount = accounts[0];
  const {
    customer,
    loading: loadingCustomer,
    writeCustomerCache,
  } = useAccountCustomer();
  const preferredLanguage: string = customer?.prefLanguage || '';

  const accountType =
    selectedAccount?.accountType === 'COM'
      ? OnlineAccountType.PgeCommercialAcct
      : OnlineAccountType.PgeResidentialAcct;
  const serviceAddress = premiseInfoToServiceAddress(
    selectedAccount?.premiseInfo?.[0],
  );

  const { data: personsData, loading: personsLoading } = useAuthQuery<
    { getPersonsForAccount: AccountPerson[] },
    QueryGetPersonsForAccountArgs
  >(getPersonsForAccountQuery, {
    variables: {
      encryptedAccountNumber,
    },
    skip: !Boolean(encryptedAccountNumber),
    fetchPolicy: 'network-only',
  });

  const persons = compact(personsData?.getPersonsForAccount);

  const { data: addressData, loading: addressLoading } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getMailingAddressForAccountQuery, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    skip: !accountParams,
    fetchPolicy: 'network-only',
  });
  const mailingAddress = serviceAddressToAddress(
    addressData?.getAccountDetails?.[0]?.mailingAddress,
  );

  const {
    customerContactInfo,
    writeCustomerContactCache,
    loading: contactLoading,
  } = fetchContactDetails();

  const contactDetail = customerContactInfo?.contactDetails;
  const pnpPhoneData =
    contactDetail && contactDetail.find(pnp => pnp?.contactType === 'PNP');
  const pnpPhone = pnpPhoneData?.contactValue
    ? parsePhoneNumber(pnpPhoneData?.contactValue)
    : '';
  const mobNumberData =
    contactDetail && contactDetail.find(mob => mob?.contactType === 'MOB');
  const mobNumber = mobNumberData?.contactValue
    ? parsePhoneNumber(mobNumberData?.contactValue)
    : '';
  const altNumberData =
    contactDetail &&
    contactDetail.find(altMob => altMob?.contactType === 'ALT');
  const altNumber = altNumberData?.contactValue
    ? parsePhoneNumber(altNumberData?.contactValue)
    : '';

  const commercialContactInfo = {
    contactName: '',
    primaryPhone: '',
    primaryPhoneExt: '',
    bookKeeperName: '',
    bookKeeperPhone: '',
    bookKeeperPhoneExt: '',
  };
  const loading = !!(
    loadingAccountList ||
    personsLoading ||
    addressLoading ||
    loadingCustomer ||
    contactLoading
  );
  const validationRules =
    accountType === OnlineAccountType.PgeResidentialAcct
      ? ResidentialFormValidationRules
      : CommercialFormValidationRules;
  const updateInfoFormProps = useValidationForm(
    populateFormValues(
      customer,
      persons,
      mailingAddress,
      pnpPhone,
      mobNumber,
      altNumber,
      commercialContactInfo,
    ),
    validationRules as any,
    {
      oldState: {
        persons,
      },
    },
  );

  const initialFormState = useValidationForm(
    populateFormValues(
      customer,
      persons,
      mailingAddress,
      pnpPhone,
      mobNumber,
      altNumber,
      commercialContactInfo,
    ),
    validationRules as any,
    {
      oldState: {
        persons,
      },
    },
  );

  useEffect(() => {
    if (!loading) {
      updateInfoFormProps.setFormState(
        populateFormValues(
          customer,
          persons,
          mailingAddress,
          pnpPhone,
          mobNumber,
          altNumber,
          commercialContactInfo,
          customer?.prefLanguage || '',
        ),
      );
      initialFormState.setFormState(
        populateFormValues(
          customer,
          persons,
          mailingAddress,
          pnpPhone,
          mobNumber,
          altNumber,
          commercialContactInfo,
          customer?.prefLanguage || '',
        ),
      );
    }
  }, [loading]);

  const [updateActInfoMutation, { loading: submitLoading }] = useMutation<
    { updateAccountInformation: boolean },
    { payload: SubmitUpdateInformationInput }
  >(updateAccountInformationMutation);

  const showMessage = (message: string, error?: boolean) => {
    notificationContext.setState({
      isOpen: true,
      severity: error ? 'error' : 'success',
      variant: 'filled',
      message: t(message),
    });
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  };

  const handleFormSubmit = async (): Promise<void> => {
    const { errors } = updateInfoFormProps.handleSubmit();
    if (errors && errors.length) {
      if (typeof window !== 'undefined') {
        window.scrollTo(0, 0);
      }
      return;
    }
    await wrapWithLoader(async () => {
      if (
        isNullOrEmpty(updateInfoFormProps, ['address', 'zip', 'city', 'state'])
      ) {
        return await submitUpdate(false);
      }
      const country = getPostalCountry(updateInfoFormProps?.zip.value);
      const params: QuickAddressSearchParams = {
        city: updateInfoFormProps.city.value,
        state: updateInfoFormProps.state.value,
        addressLine1: updateInfoFormProps.address.value,
        postal: updateInfoFormProps.zip.value,
        country: country,
        isMailingAddress: true,
      };
      const results = await search(params);
      setQasState({ results, params });
    })();
  };

  const handleQasSubmit = async (
    isVerified: boolean,
    address?: SuggestedAddress,
  ): Promise<void> => {
    const { errors } = updateInfoFormProps.handleSubmit();
    if (errors && errors.length) {
      if (typeof window !== 'undefined') {
        window.scrollTo(0, 0);
      }
      return;
    }

    if (address) {
      updateInfoFormProps.city.value = address.city;
      updateInfoFormProps.state.value = address.state;
      updateInfoFormProps.address.value = address.addressLine1;
      updateInfoFormProps.zip.value = address?.postal
        ? address.postal.replace(/\s/g, '')
        : '';
    }

    await submitUpdate(isVerified);
  };

  const submitUpdate = async (
    isMailingAddressQasVerified: boolean,
  ): Promise<void> => {
    try {
      const { errors } = updateInfoFormProps.handleSubmit();
      if (errors && errors.length) {
        if (typeof window !== 'undefined') {
          window.scrollTo(0, 0);
        }
        return;
      }

      const payload = populateUpdateInfoPayload({
        accountType,
        encryptedAccountNumber,
        encryptedPersonId,
        commercialContactInfo,
        mailingAddress,
        isMailingAddressQasVerified,
        serviceAddress,
        persons,
        primaryPhone: pnpPhone,
        mobilePhone: mobNumber,
        alternatePhone: altNumber,
        preferredLanguage: preferredLanguage,
        updateForm: updateInfoFormProps,
        isMobilePhoneSameAsPrimaryPhone,
        ChangeNameReasons,
      });

      const isNoChange: boolean = validateChanges(payload);

      if (isNoChange) {
        showMessage('RENEWABLE_CONFIG_ENROLLMENT_NO_CHANGES_ERROR', true);
        return;
      }

      const { data: updateResponse } = await updateActInfoMutation({
        variables: {
          payload,
        },
      });
      if (updateResponse?.updateAccountInformation) {
        setComplete(true);
        const updateContactDetails = [];
        pnpPhoneData &&
          updateContactDetails.push({
            encryptedContactId: pnpPhoneData?.encryptedContactId,
            contactType: pnpPhoneData?.contactType,
            contactValue: updateInfoFormProps.primaryPhone.value,
            isPrimary: pnpPhoneData?.isPrimary,
          });

        mobNumberData &&
          updateContactDetails.push({
            encryptedContactId: mobNumberData?.encryptedContactId,
            contactType: mobNumberData?.contactType,
            contactValue: updateInfoFormProps.mobilePhone.value,
            isPrimary: mobNumberData?.isPrimary,
          });

        altNumberData &&
          updateContactDetails.push({
            encryptedContactId: altNumberData?.encryptedContactId,
            contactType: altNumberData?.contactType,
            contactValue: updateInfoFormProps.alternatePhone.value,
            isPrimary: altNumberData?.isPrimary,
          });
        writeCustomerContactCache({ contactDetails: updateContactDetails });
        writeCustomerCache({
          prefLanguage: updateInfoFormProps.preferredLanguage.value,
        });
        typeof window !== 'undefined' && window.scrollTo(0, 0);
      } else {
        showMessage('ERROR_UPDATE_ACCOUNT_INFO', true);
      }
    } catch (error) {
      showMessage('ERROR_UPDATE_ACCOUNT_INFO', true);
    }
  };

  const isProvinceSelected = isCanadianState(updateInfoFormProps.state.value);

  return {
    loading: loading || submitLoading,
    isComplete,
    accountType,
    accountNumber,
    statesAndProvincesGrouping,
    serviceAddress,
    persons,
    pnpPhone,
    mobNumber,
    altNumber,
    preferredLanguage,
    mailingAddress,
    updateInfoFormProps,
    initialFormState,
    ChangeNameReasons,
    preferredLanguages,
    handleFormSubmit,
    commercialContactInfo,
    isProvinceSelected,
    qasState,
    setQasState,
    handleQasSubmit,
    isMobilePhoneSameAsPrimaryPhone,
    setIsMobilePhoneSameAsPrimaryPhone,
  };
};
