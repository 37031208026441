import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../../themes/main-colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subField: {
      '& input': {
        padding: theme.spacing(1, 2),
      },
    },
    icon: {
      color: theme.palette.success.main,
    },
    divided: {
      borderBottom: `1px solid ${colors.lightGray1}`,
      paddingBottom: theme.spacing(2),
    },
    colorTextPrimary: {
      color: colors.noirBlur,
      margin: theme.spacing(2, 0),
    },
    boldText: {
      fontWeight: 'bold',
    },
    alerts: {
      display: 'flex',
      flexDirection: 'column',
    },
    alertsButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '1em',
    },
    header: {
      margin: theme.spacing(1, 2, 2, 6),
      color: colors.noirBlur,
      fontWeight: 800,
    },
    accountCard: {
      padding: theme.spacing(2),
      width: '100%',
    },
    subCard: {
      paddingTop: theme.spacing(2),
    },
    text: {
      margin: theme.spacing(1, 2, 2, 2),
      color: colors.noirBlur,
    },
    textLeft: {
      margin: theme.spacing(1, 6, 2, 2),
      color: colors.noirBlur,
    },
    gridContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    noLastBorder: {
      '& > *:last-child': {
        borderBottom: 'none',
      },
    },
    callModal: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      padding: theme.spacing(4, 6, 4, 4),
      boxShadow: theme.shadows[5],
      backgroundColor: theme.palette.background.paper,
      [theme.breakpoints.down('sm')]: {
        top: '50%',
        left: 10,
        right: 10,
        transform: 'translate(0, -50%)',
      },
    },
    row: {
      minHeight: theme.spacing(7),
      borderBottom: `1px solid ${colors.lightGray1}`,
      flex: 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& > *': {
        flex: 1,
      },
      '& > *:first-child': {
        flex: 8,
      },
    },
    rightColumns: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    footerText: {
      '& p': {
        marginBottom: 0,
      },
      '& p:not(:first-child)': {
        marginTop: 0,
      },
    },
    modalBodytext: {
      marginRight: '.75rem',
    },
    manageAlertsSuccess: {
      '& p': {
        margin: '5px 0',
      },
      '& .MuiAlert-message': {
        width: '100%',
      },
    },
    addPerson: {
      top: '15px',
      right: '15px',
    },
    deletePerson: {
      top: '15px',
    },
    alertTitle: {
      fontWeight: 300,
      margin: '-10px 0 0',
      fontFamily: 'Forma-DJR-Display'
    },
    select: {
      background: '#fff',
      width: '100%',
    },
    '.MuiSelect-select': {
      '&:focus': {
        background: '#fff',
      },
    },
    removePersonDataModalWrapper: {
      '& .MuiDialog-paperWidthSm': {
        maxWidth: '600px',
        width: '80%',
      },
    },
    removePersonDataModal: {
      padding: theme.spacing(3),
      borderRadius: '5px',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(4, 3, 3, 3),
      },
    },
    closeBtn: {
      position: 'absolute',
      top: 10,
      right: 10,
      padding: 0,
    },
    modalBody: {
      marginTop: theme.spacing(1),
      '& > *': {
        marginTop: theme.spacing(1),
      },
    },
    dialogBtn: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
    confirmBtn: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  }),
);
