import { isCanadianState } from './../components/utility/state-dropdown';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from './useTranslation';
import useSelectedAccountParams from './useSelectedAccountParams';
import useAuthQuery from './useAuthQuery';
import useFormState from './useFormState';
import useAccountCustomer from './useAccountCustomer';
import validationRules, {
  Model,
} from '../components/oregon-consumer-privacy-act/OCPAForm.rules';
import {
  AccountDetail,
  AccountStatusType,
  PersonVerificationType,
} from '../__generated__/pge-types';
import gql from 'not-graphql-tag';
import { parsePhoneNumber } from '../util/format';
import { useApolloClient } from '@apollo/react-hooks';
import { splitName } from '../util/string-utils';
import { NotificationsContext } from '../providers/NotificationsProvider';
import useUtil from './pge-plus/useUtil';
import useAccountDetailList from './useAccountDetailList';
import useAccountListHeader from '../components/account-summary/multi-accounts/useAccountListHeader';
import { VirtualAccountType } from '../constants/customEnum';
import useWrapWithLoader from './useWrapWithLoading';

const { t } = useTranslation();

export const getAccountDetailsQuery = gql`
  query getAccountDetails($params: AccountDetailParams!) {
    getAccountDetails(params: $params) {
      accountNumber
      relationType
      mailingAddress {
        addressLine1
        city
        state
        postal
      }
      premiseInfo {
        addressLine1
        city
        state
        postal
      }
    }
  }
`;
export const OCPAInformationMutation = gql`
  mutation OcpaFormSubmit($payload: OCPAFormSubmitRequest) {
    ocpaFormSubmit(payload: $payload) {
      isSuccessful
    }
  }
`;

export const getAccountsList = gql`
  query getAllActiveAccountDetailList($params: AccountDetailListParams!) {
    getAccountDetailList(params: $params) {
      accounts {
        accountNumber
        relationType
      }
    }
  }
`;

export interface DefaultFormValue {
  value: string | number | boolean;
  errorMessage: string | null;
}
export default () => {
  const { accountParams } = useSelectedAccountParams();
  const apolloClient = useApolloClient();
  const { loading: hookLoading, data: accountDetails } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getAccountDetailsQuery, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    skip: !accountParams,
  });

  const notificationContext = useContext(NotificationsContext);
  const { setErrorNotification } = useUtil();
  const { accountListParams } = useAccountListHeader();
  const { wrapWithLoader } = useWrapWithLoader();

  const { accounts } = useAccountDetailList(
    getAccountsList,
    VirtualAccountType.ALL_ACCTS,
    {
      accountListParams: {
        ...accountListParams,
        accountStatus: AccountStatusType.Active,
        pageSize: 'All',
      },
    },
  );

  const paylodAccountsList = accounts.map(account => ({
    number: account.accountNumber,
    type: account.relationType,
  }));
  const { customer, loading: loadingCustomer } = useAccountCustomer();

  const form = useFormState(
    {
      firstName: '',
      middleName: '',
      lastName: '',
      phoneType: '',
      phoneValue: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      email: '',
      confirmEmail: '',
      contactMailType: 'email',
      idType: '',
      idValue: '',
      personalInfoReport: false,
      deleteInfo: false,
      marketingCommunication: false,
      disclaimer: false,
    },
    {
      validate: validationRules,
      validationContext: {
        t,
        personEmail: customer?.email,
      },
    },
  );

  const [isComplete, setComplete] = useState(false);
  const [ocpaOptionsReqError, setOcpaOptionsReqError] = useState(false);

  const selectedAccount = accountDetails?.getAccountDetails?.[0];
  const premiseInfo = selectedAccount?.premiseInfo?.[0];
  const serviceAddress = selectedAccount?.mailingAddress;

  const contactDetail = customer?.contactDetails;
  const pnpPhoneData =
    contactDetail && contactDetail.find(pnp => pnp?.contactType === 'PNP');
  const pnpPhone = pnpPhoneData?.contactValue
    ? parsePhoneNumber(pnpPhoneData?.contactValue)
    : '';
  const mobNumberData =
    contactDetail && contactDetail.find(mob => mob?.contactType === 'MOB');
  const mobNumber = mobNumberData?.contactValue
    ? parsePhoneNumber(mobNumberData?.contactValue)
    : '';
  const altNumberData =
    contactDetail &&
    contactDetail.find(altMob => altMob?.contactType === 'ALT');
  const altNumber = altNumberData?.contactValue
    ? parsePhoneNumber(altNumberData?.contactValue)
    : '';
  type ocpaContactDetailsType = [{ contactType: string; contactValue: string }];
  const OcpaContactDetails: ocpaContactDetailsType = [
    { contactType: '', contactValue: '' },
  ];

  pnpPhoneData &&
    OcpaContactDetails.push({
      contactType:
        pnpPhoneData?.contactType === PersonVerificationType.Pnp
          ? t('PRIMARY_PHONE')
          : '',
      contactValue: parsePhoneNumber(pnpPhoneData?.contactValue),
    });

  mobNumberData &&
    OcpaContactDetails.push({
      contactType:
        mobNumberData?.contactType === PersonVerificationType.Mob
          ? t('MOB_PHONE')
          : '',
      contactValue: parsePhoneNumber(mobNumberData?.contactValue),
    });

  altNumberData &&
    OcpaContactDetails.push({
      contactType:
        altNumberData?.contactType === PersonVerificationType.Alt
          ? t('ALT_PHONE')
          : '',
      contactValue: parsePhoneNumber(altNumberData?.contactValue),
    });

  function getLabel(value: string | undefined): any {
    const type = OcpaContactDetails?.find(item => item.contactType === value);
    return type ? type.contactType : '';
  }

  const pnpType = getLabel(t('PRIMARY_PHONE'));
  const mobType = getLabel(t('MOB_PHONE'));
  const altType = getLabel(t('ALT_PHONE'));

  const phoneTypeToDispaly = pnpType
    ? pnpType
    : mobType
    ? mobType
    : altType
    ? altType
    : '';

  const CustomerSplitName = splitName(customer?.personName || '');
  const firstN = CustomerSplitName?.firstName;
  const middleN = CustomerSplitName?.middleName;
  const lastN = CustomerSplitName?.lastName;

  const companyN = !firstN && !middleN && !lastN && customer?.personName;

  const zipValue = serviceAddress
    ? serviceAddress.postal &&
      serviceAddress.state &&
      !isCanadianState(serviceAddress.state)
      ? serviceAddress.postal.substring(0, 5)
      : serviceAddress.postal
    : premiseInfo
    ? premiseInfo.postal &&
      premiseInfo.state &&
      !isCanadianState(premiseInfo.state)
      ? premiseInfo.postal.substring(0, 5)
      : premiseInfo.postal
    : null;

  useEffect(() => {
    void form.setValue('firstName', firstN || companyN || '');
    void form.setValue('middleName', middleN || '');
    void form.setValue('lastName', lastN || '');
    void form.setValue('email', customer?.email);
    void form.setValue('phoneType', phoneTypeToDispaly);
    void form.setValue(
      'phoneValue',
      pnpPhone ? pnpPhone : mobNumber ? mobNumber : altNumber ? altNumber : '',
    );
    void form.setValue(
      'address',
      serviceAddress?.addressLine1 || premiseInfo?.addressLine1,
    );
    void form.setValue('city', serviceAddress?.city || premiseInfo?.city);
    void form.setValue('state', serviceAddress?.state || premiseInfo?.state);
    void form.setValue('zip', zipValue);
  }, [selectedAccount, customer]);

  async function submitOCPA(
    data: Model,
    encryptedPersonId: string | undefined,
  ): Promise<{
    isSuccessful: boolean;
  }> {
    const queryResult = await apolloClient.mutate({
      mutation: OCPAInformationMutation,
      variables: {
        payload: {
          accounts: paylodAccountsList,
          addressLine1: data.address,
          city: data.city,
          state: data.state,
          zipcode: data.zip,
          contactPreference: data.contactMailType,
          enteredEmail: data.email,
          firstName: data.firstName,
          idTypeSelection: data.idType.toString(),
          last4Id: data.idValue,
          lastName: data.lastName,
          middleName: data.middleName,
          phoneContactType: data.phoneType,
          phoneNumber: data.phoneValue,
          requestDelete: data.deleteInfo ? 'Y' : 'N',
          requestReport: data.personalInfoReport ? 'Y' : 'N',
          requestOptOutMkt: data.marketingCommunication ? 'Y' : 'N',
          requestAgreedTC: data.disclaimer ? 'Y' : 'N',
          encryptedPersonId: encryptedPersonId,
        },
      },
    });
    return queryResult?.data?.ocpaFormSubmit;
  }

  const handleOCPAFormSubmit = wrapWithLoader(
    async (data: Model): Promise<void> => {
      if (
        !(
          data.personalInfoReport ||
          data.deleteInfo ||
          data.marketingCommunication
        ) ||
        !data.disclaimer
      ) {
        {
          setOcpaOptionsReqError(true);
        }
        return;
      }

      try {
        const ocpaFormSubmitResponse = await submitOCPA(
          data,
          customer?.encryptedPersonId,
        );
        if (ocpaFormSubmitResponse.isSuccessful) {
          setComplete(true);
        } else {
          notificationContext.setState({
            isOpen: true,
            message: t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
            severity: 'error',
          });
          if (typeof window !== 'undefined') {
            window.scrollTo(0, 0);
          }
        }
      } catch (e) {
        setErrorNotification(true);
        return;
      }
    },
  );
  const handleSubmit = form.submit(handleOCPAFormSubmit);
  return {
    loading: loadingCustomer || hookLoading,
    isComplete,
    ocpaOptionsReqError,
    customer,
    form,
    handleSubmit,
  };
};
