import React, { useEffect, useState, useContext } from 'react';
import ProfileLayout from '../../components/profile-layout';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useTranslation } from '../../hooks/useTranslation';
import AccountDropdown from '../../components/account-dropdown';
import {
  ManageAlertsLanding,
  ManageAlertsIneligible,
  ManageAlertsConfirmPhone,
  ManageAlertsConfirm,
  useManageAlerts,
  ManageAlertsSuccess,
  AlertsModal,
} from '../../components/account-summary/manage-alerts';
import Backdrop from '../../components/backdrop';
import { Router, navigate, useLocation } from '@reach/router';
import ROUTES from '../../routes';
import { Model } from '../../components/account-summary/manage-alerts/types';
import useWrapWithLoader from '../../hooks/useWrapWithLoading';
import {
  UpdateValidatePhoneError,
} from '../../__generated__/pge-types';
import { NotificationsContext } from '../../providers/NotificationsProvider';
import Helmet from 'react-helmet';

export default function ManageAlertsPage(_: { path?: string }) {
  const { t } = useTranslation();
  const [selectedPerson, setSelectedPerson] = useState();
  const {
    loading,
    alerts,
    form,
    defaultFormValues,
    showWeeklyUsageAlertsText,
    setShowWeeklyUsageAlertsText,
    showOutageNotificationAlertsText,
    setShowOutageNotificationAlertsText,
    account,
    service,
    refetchAccount,
    personList,
    handleChangePersonDetails,
    handleDeletePersonDetails,
    displayError,
    refetchPerson,
    customer,
    mainPersonEmail
   } = useManageAlerts({
    params: { selectedPersonParams: selectedPerson ? selectedPerson! : null },
  });
  const { wrapWithLoader } = useWrapWithLoader();
  const notificationsContext = useContext(NotificationsContext);
  const location = useLocation();

  const [encryptedActivationCode, setEncryptedActivationCode] = useState<
    string | null
  >(null);

  const [encryptedContactId, setEncryptedContactId] = useState<
    string | null | undefined
  >(null);
  const [encryptedEmailId, setEncryptedEmailId] = useState<
    string | null | undefined
  >(null);
  const [recentlyAddedPerson, setRecentlyAddedPerson] = useState();

  useEffect(() => {
    if (account && !account?.isLoggedInUserOnAccount) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.MANAGE_ALERTS_INELIGIBLE);
    } else if (
      account &&
      account?.isLoggedInUserOnAccount &&
      location.pathname !== ROUTES.MANAGE_ALERTS
    ) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.MANAGE_ALERTS);
    }
  }, [account]);

  useEffect(() => {
    if (customer && personList !== undefined) {
      const person: any = personList?.find(
        (personD: any) => personD.personId === customer?.personId,
      );
      setSelectedPerson(person);      
    }
    return () => {
      setSelectedPerson(undefined); 
    };
  }, [personList, customer]);

  useEffect(() => {
    if (recentlyAddedPerson) {
      const {
        personId
      } = recentlyAddedPerson as any;
      const person: any = personList?.find(
        (p: any) => p.personId === personId,
      );
      if (person) {
        setSelectedPerson(person);
        setRecentlyAddedPerson(undefined);
      }
    }
  }, [recentlyAddedPerson, personList]);  

  function handleIneligibleSubmit() {
    return navigate(ROUTES.ACCOUNT);
  }
  const handleLandingSubmit = wrapWithLoader(async (data: Model) => {
    if (data.phoneNumber &&
      data.phoneNumber !== defaultFormValues.phoneNumber) {
      const {
        encryptedActivationCode: encryptedCode,
      } = await service.sendActivationCode(data.phoneNumber);

      setEncryptedActivationCode(encryptedCode);

      return navigate(ROUTES.MANAGE_ALERTS_CONFIRM_PHONE);
    } 
    else {
      return navigate(ROUTES.MANAGE_ALERTS_CONFIRM);
    }
  });

  const handleChangePerson = wrapWithLoader(async (selectedPersonData: any) => {
    setSelectedPerson(selectedPersonData);
    await handleChangePersonDetails();
  });

  const handleDeletePerson = wrapWithLoader(async () => {
    await handleDeletePersonDetails(form.values, selectedPerson!);    
  });
  const handleConfirmPhoneSubmit = wrapWithLoader(
    async (activationCode: string, onInvalid: () => void) => {
      const {
        success,
        error,
        encryptedContactId: encryptContactId,
        isAlertSelected,
      } = await service.validateandUpdatePhoneNumber(
        activationCode,
        String(encryptedActivationCode),
        form.values,
        defaultFormValues,
      );

      if (!success && error === UpdateValidatePhoneError.InvalidCode) {
        return onInvalid();
      } else if (
        (!success && error === UpdateValidatePhoneError.ContactExists) ||
        (!success &&
          error === UpdateValidatePhoneError.PhoneAttachedToActiveAccount)
      ) {
        form.setError('phoneNumber', t('MANAGE_ALERTS_BAD_PHONE_NUMBER'));
        return navigate(ROUTES.MANAGE_ALERTS);
      } else {
        if (isAlertSelected) {
          setEncryptedContactId(encryptContactId);
          return navigate(ROUTES.MANAGE_ALERTS_CONFIRM);
        } else {
          await refetchAccount();
          return navigate(ROUTES.MANAGE_ALERTS);
        }
      }
    },
  );

  function handleTooManyFailures() {
    setEncryptedActivationCode(null);
    return navigate(ROUTES.MANAGE_ALERTS);
  }

  const handleConfirmSubmit = wrapWithLoader(async () => {
    const { error, removedPhoneNumber, success } = await service.updateAlerts(
      form.values,
      defaultFormValues,
      encryptedContactId!,
      encryptedEmailId!
    );

    if (!error || success) {
      await refetchAccount();
      await navigate(ROUTES.MANAGE_ALERTS_SUCCESS);

      if (removedPhoneNumber) {
        notificationsContext.setState({
          isOpen: true,
          severity: 'success',
          message: t('PHONE_NUMBER_REMOVED'),
        });
      }      
    } else if (error) {
      return navigate(ROUTES.MANAGE_ALERTS);
    } else {
      return Promise.reject('Failed to update alerts');
    }
  });

  return (
    <>
      <Helmet>
        <title>{t('MANAGE_USERS_AND_ALERTS_TITLE')}</title>
      </Helmet>
      <ProfileLayout dense={false}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Box marginBottom={2}>
              <Typography variant="h1">
                {t('MANAGE_USERS_AND_ALERTS_TITLE')}
              </Typography>
            </Box>
          </Grid>

          <Grid item>
            <AccountDropdown />
          </Grid>
          <Grid item>
            {loading && <Backdrop forceOpen />}
            {!loading && (
              <Router basepath="/">
                <ManageAlertsLanding
                  path={ROUTES.MANAGE_ALERTS}
                  form={form}
                  alerts={alerts}
                  defaultFormValues={defaultFormValues}
                  onSubmit={handleLandingSubmit}
                  personList={personList}
                  handleChangePerson={handleChangePerson}
                  selectedPerson={selectedPerson}
                  handleDeletePerson={handleDeletePerson}
                  displayError={displayError}
                  loggedInPersonId={customer?.personId}
                  refetchPerson={refetchPerson}
                  setRecentlyAddedPerson={setRecentlyAddedPerson}
                  mainPersonEmail={mainPersonEmail}
                  loggedInPersonType={personList?.find(per => per.personId === customer?.personId)?.accountRelTypeCd}
                  loggedInPersonName={customer?.personName}
                />
                <ManageAlertsIneligible
                  path={ROUTES.MANAGE_ALERTS_INELIGIBLE}
                  onSubmit={handleIneligibleSubmit}
                />
                <ManageAlertsConfirmPhone
                  path={ROUTES.MANAGE_ALERTS_CONFIRM_PHONE}
                  onSubmit={handleConfirmPhoneSubmit}
                  phoneNumber={form.values.phoneNumber}
                  hasActivationCode={Boolean(encryptedActivationCode)}
                  onTooManyFailures={handleTooManyFailures}
                  onPrevious={() => navigate(ROUTES.MANAGE_ALERTS)}
                />
                <ManageAlertsConfirm
                  path={ROUTES.MANAGE_ALERTS_CONFIRM}
                  form={form}
                  defaultFormValues={defaultFormValues}
                  onPrevious={() => navigate(ROUTES.MANAGE_ALERTS)}
                  alerts={alerts}
                  onSubmit={handleConfirmSubmit}
                />
                <ManageAlertsSuccess path={ROUTES.MANAGE_ALERTS_SUCCESS} />
              </Router>
            )}
          </Grid>
        </Grid>
        <AlertsModal
          open={showWeeklyUsageAlertsText}
          onClose={() => setShowWeeklyUsageAlertsText(false)}
          title={t('USAGE_TITLE')}
          bodyText={[
            t('WEEKLY_NOTIFICATION_DATES'),
            t('WEEKLY_USAGE_NOTIFICATION'),
          ]}
        />
        <AlertsModal
          open={showOutageNotificationAlertsText}
          onClose={() => setShowOutageNotificationAlertsText(false)}
          title={t('OUTAGE_NOTIFICATIONS')}
          bodyText={
            account?.commPreferences
              ?.find(a => a?.notificationType === 'OUT')
              ?.deliveryTypeDetails?.find(a1 => a1?.deliveryType === 'SMS')
              ?.contactDetails?.some(con => con?.isSelected)
              ? [t('OUTAGE_NOTIFICATION_TOOLTIP_NOT_ENROLLED_USER')]
              : [t('OUTAGE_NOTIFICATION_TOOLTIP_ENROLLED_USER')]
          }
        />
      </ProfileLayout>
    </>
  );
}
