import React, { useState } from 'react';

export interface DefaultFormValuesProps {
  [k: string]: {
    value: string | boolean;
    errorMessage: string | null;
  };
}

export default (
  defaultFormValues: any,
  validationRules: (arg0?: string, arg1?: any) => any,
  additionalState?: object,
) => {
  const [formState, setFormState] = useState(defaultFormValues);
  const [errors, setErrors] = useState({});

  const setErrorMessage = (name: string, errorMessage: string): void => {
    setFormState((prevState: any) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        errorMessage: errorMessage,
      },
    }));
  };

  const validateField = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const field = event.target.name;
    const validate: any = validationRules('change', {
      ...formState,
      ...additionalState,
    });
    setErrorMessage(field, validate?.[field]?.(event.target.value));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof event.persist === 'function') {
      event.persist();
    }

    let value: 'checked' | 'value' = 'value';

    if (event.target.type === 'checkbox') {
      value = 'checked';
    }

    setFormState((prevState: any) => ({
      ...prevState,
      [event?.target?.name]: {
        ...prevState[event?.target?.name],
        value: event?.target?.[value],
      },
    }));

    if (formState?.[event?.target?.name]?.errorMessage) {
      validateField(event);
    }
  };

  const validateFields = () => {
    const updates = {} as any;
    const fieldErrors = [] as any;

    for (const field in formState) {
      const validate: any = validationRules('submit', {
        ...formState,
        ...additionalState,
      });
      const errorMessage = validate?.[field]?.(formState[field].value);
      updates[field] = { ...formState[field], errorMessage };
      if (errorMessage) {
        fieldErrors.push({ field, errorMessage });
      }
    }

    setFormState((prevState: any) => ({
      ...formState,
      ...prevState,
      ...updates,
    }));

    setErrors(fieldErrors);

    return {
      data: updates,
      errors: fieldErrors,
    };
  };

  const handleSubmit = (event?: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    if (event && event.persist) {
      event.persist();
    }
    return validateFields();
  };

  return {
    ...formState,
    setFormState,
    handleSubmit,
    handleChange,
    setErrorMessage,
    onBlur: (event: any) => {
      event.persist();
      handleChange(event);
      validateField(event);
    },
    errors,
  };
};
