import {
  validatePhoneNumber,
  validateAddressField,
  validateEmail,
  validateZipCode,
  validateExistsAndAlphabetCharacters,
  validateNameField,
  validatePostalCode,
  validateAlphabetCharacters,
} from '../../util/form-validation';
import { UseTranslationHook, useTranslation } from '../../hooks/useTranslation';
import { convertValidationRules } from '../../hooks/useFormState';
import { isCanadianState } from '../utility/state-dropdown';

const { t } = useTranslation();

export type Model = {
  firstName: string;
  middleName?: string;
  lastName: string;
  phoneType: string;
  phoneValue: any;
  address: string;
  city: string;
  state: string;
  zip: string;
  personalInfoReport: boolean;
  deleteInfo: boolean;
  marketingCommunication: boolean;
  disclaimer: boolean;
  email: string;
  confirmEmail?: string;
  contactMailType: string;
  idType: string;
  idValue: string;
};

export const consumerIDType = [
  { value: t('SOCIAL_SECURITY_NUMBER'), label: t('SOCIAL_SECURITY_NUMBER') },
  {
    value: `${t('DRIVERS_LICENSE')} / ${t('STATE_ID_CARD')}`,
    label: `${t('DRIVERS_LICENSE')} / ${t('STATE_ID_CARD')}`,
  },
  { value: t('MATRICULA_ID'), label: t('MATRICULA_ID') },
  { value: t('PASSPORT'), label: t('PASSPORT') },
  { value: t('RESIDENT_ALIEN'), label: t('RESIDENT_ALIEN') },
  { value: t('VISA_ID'), label: t('VISA_ID') },
  { value: t('MILITARY_ID'), label: t('MILITARY_ID') },
  { value: t('OR_TRIBAL_ID'), label: t('OR_TRIBAL_ID') },
];

export const phoneType = [
  { value: t('ALT_PHONE'), label: t('ALT_PHONE') },
  { value: t('PRIMARY_PHONE'), label: t('PRIMARY_PHONE') },
  { value: t('MOB_PHONE'), label: t('MOB_PHONE') },
  { value: t('INTERNATIONAL'), label: t('INTERNATIONAL') },
  { value: t('BUSINESS_OR_WORK'), label: t('BUSINESS_OR_WORK') },
  { value: t('BUSINESS_OR_WORK_MOBILE'), label: t('BUSINESS_OR_WORK_MOBILE') },
];

function validateDisclaimer(value: boolean, context: AdditionalContext) {
  if (!value) {
    return context.t('ERROR_DISCLAIMER');
  }
  return null;
}

function validatePhoneType(value: any, context: AdditionalContext) {
  if (!value) {
    return `${context.t('ENTER')} ${context
      .t('CONTACT_PHONE_TYPE')
      .toLowerCase()}`;
  }
  return null;
}

function validateIDType(value: any, context: AdditionalContext) {
  if (!value) {
    return context.t('PLEASE_SELECT_ID_TYPE');
  }
  return null;
}

function validateIDValue(value: string, context: AdditionalContext) {
  if (!(value.length === 4)) {
    return context.t('ENTER_LAST_FOUR_CHARACTERS_OF_YOUR_ID');
  }
  return null;
}

type AdditionalContext = {
  t: UseTranslationHook['t'];
  personEmail: string | undefined;
};

export default convertValidationRules<Model, AdditionalContext>(context => {
  return {
    firstName: validateExistsAndAlphabetCharacters.bind(null, t('NAME')),
    middleName: validateAlphabetCharacters.bind(null, t('MIDDLE_NAME')),
    lastName: validateExistsAndAlphabetCharacters.bind(null, t('LAST_NAME')),
    email: validateEmail,
    phoneType: validatePhoneType,
    phoneValue: validatePhoneNumber,
    disclaimer: validateDisclaimer,
    idType: validateIDType,
    idValue: validateIDValue,
    confirmEmail: value => {
      if (context.personEmail === context.values.email) {
        return null;
      }
      if (!value?.length) {
        return t('BLANK_EMAIL');
      }
      if (value !== context.values.email) {
        return t('ERROR_EMAILS_DO_NOT_MATCH');
      }
      return null;
    },
    address: validateAddressField,
    city: validateExistsAndAlphabetCharacters.bind(null, t('CITY')),
    state: validateNameField.bind(null, t('STATE')),

    zip: isCanadianState(context?.values?.state)
      ? validatePostalCode
      : validateZipCode,
  };
});
