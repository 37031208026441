import React, { Fragment, useEffect } from 'react';
import {
  Box,
  Paper,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useTranslation } from '../../hooks/useTranslation';
import PGEButton from '../buttons/PGE-Button';
import { navigate } from '@reach/router';
import routes from '../../routes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      margin: '.5rem 0px 1rem 0rem',
      textAlign: 'center',
    },
    greenCheck: {
      fill: 'green',
      fontSize: '5rem',
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: 20,
      fontWeight: 600,
      margin: '2rem 1.5rem',
    },
  }),
);

type Props = {
  path?: string;
};

export default function OCPASuccessForm(_: Props) {
  const { t, richT } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Paper css={{ marginTop: 24 }}>
        <Box>
          <Box className={classes.box}>
            <CheckCircleIcon className={classes.greenCheck} />
            <Typography variant="h1" className={classes.title}>
              {t('OCPA_REQUEST_RECEIVED_TITLE')}
            </Typography>
            <Box>
              <Typography
                variant="body1"
                css={{ margin: '.5rem 0px 1rem 0rem' }}
              >
                {richT('OCPA_REQUEST_RECEIVED_MESSAGE')}
              </Typography>
            </Box>
            <Box
              css={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                marginTop: '24px',
              }}
            >
              <PGEButton
                type={'button'}
                variant={'contained'}
                onClick={() => navigate(routes.PRIVACY_POLICY)}
              >
                {t('OK')}
              </PGEButton>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Fragment>
  );
}
