import Grid from '@material-ui/core/Grid';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React, { useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';

import useAccountDetailList from '../../../hooks/useAccountDetailList';
import { useTranslation } from '../../../hooks/useTranslation';
import colors from '../../../themes/main-colors';
import AccountLayout from '../../account-layout/AccountLayout';
import Alerts from '../../alerts';
import Button from '../../buttons';
import AccountHeroHeader from '../account-hero-header/AccountHeroHeader';
import SecureSitePromo from '../../secure-site-promo';
import AccountList from './AccountList';
import { navigate } from '@reach/router';
import ROUTES from '../../../routes';
import SortFilterPageHeader from '../sort-filter-page-header/SortFilterPageHeader';
import { format, parseISO } from 'date-fns';
import AccountGroupDropdown from '../../account-group-dropdown';
import { getAccountsList } from './queries';
import Backdrop from '../../backdrop';
import useSelectedGroupId from '../../../hooks/useSelectedGroupId';
import useSelectedAccountParams from '../../../hooks/useSelectedAccountParams';
import useAccountMulticheckState from '../../../hooks/useAccountMulticheckState';
import {
  AccountDetail,
  AccountRelationship,
} from '../../../__generated__/pge-types';
import BroadcastMessage from '../broadcast-message/BroadcastMessage';
import useAccountListHeader from './useAccountListHeader';
import { featureFlagsContext } from '../../../providers/FeatureFlagsProvider';
import { ClosedAccountsAlert } from '../../closed-accounts-alert/ClosedAccountsAlert';
import { NickNameAddressPayload } from '../../nickname-change-modal/types';
import useAccountsStore from '../../../hooks/useAccountsStore';
import useEVApplicationApi from '../../../hooks/pge-plus/useEVApplicationApi';
import {
  Application,
  ApplicationStatus,
  ApplicationType,
} from '../../../__generated__/pgeplus-types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainArea: {
      minWidth: '50em',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        padding: 0,
        maxWidth: '75em',
      },
      [theme.breakpoints.down('md')]: {
        minWidth: '15em',
      },
    },
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    buttonRow: {
      marginTop: '2em',
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'row',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    buttonContainer: {
      display: 'flex',
      marginLeft: '4px',
      [theme.breakpoints.down('md')]: {
        marginLeft: '0',
        marginTop: '1em',
        width: '100%',
        justifyContent: 'center',
      },
    },
    button: {
      background: colors.orange,
      [theme.breakpoints.down('md')]: {
        width: '80%',
      },
    },
  }),
);

type Props = {
  onSelectAccount?: (account: AccountDetail) => void;
  setApplicationInProgressData?: (value: {
    inProgress: boolean;
    accountNo?: string;
    type?: ApplicationType[] | undefined;
    applicationsData: Application[] | undefined;
  }) => void;
};

export default function MultiAccountSummary({
  onSelectAccount,
  setApplicationInProgressData,
}: Props) {
  const {
    accountListParams,
    onResetParams,
    onAccountSearch,
    onAccountSort,
    onAccountPageChange,
  } = useAccountListHeader();

  const { t } = useTranslation();
  const { selectedGroupId } = useSelectedGroupId();
  const { setAccountDisconnectedStatus } = useAccountsStore();
  const { setSelectedAccountParams } = useSelectedAccountParams();
  const { isClosedAccountsEnabled } = useContext(featureFlagsContext);
  const {
    loading,
    error,
    detailList,
    accounts,
    totalCount = 0,
    writeAccountInfoCache,
  } = useAccountDetailList(getAccountsList, selectedGroupId, {
    accountListParams,
  });

  const {
    customerAccountState: { hasInactiveAccounts },
  } = useAccountsStore();
  const accountsListCacheUpdate = (payload: NickNameAddressPayload) => {
    const idx = accounts.findIndex(a =>
      a.premiseInfo?.find(
        p => p?.encryptedPremiseId === payload.encryptedPremiseId,
      ),
    );
    accounts[idx]?.premiseInfo?.some(premise => {
      if (premise?.encryptedPremiseId === payload.encryptedPremiseId) {
        return premise?.saDetails?.some(serviceAddress => {
          if (serviceAddress?.encryptedSAId === payload.encryptedSaId) {
            serviceAddress.nickName = payload.nickName;
            return true;
          }
          return false;
        });
      }
      return false;
    });
    writeAccountInfoCache({ accounts: accounts });
  };

  const multicheckState = useAccountMulticheckState({
    query: accountListParams.query,
  });

  useEffect(() => {
    setAccountDisconnectedStatus(false, false, false);
  }, []);

  useEffect(() => {
    if (multicheckState.isCurrentChargesError) {
      typeof window !== 'undefined' && window.scrollTo(0, 0);
    }
  }, [multicheckState.isCurrentChargesError]);

  const { getApplicationsByAccount } = useEVApplicationApi();

  useEffect(() => {
    void getApplicationsData();
  }, [accounts?.length]);

  const getApplicationsData = async () => {
    const filteredAccounts = accounts
      ?.filter(
        acc =>
          acc?.relationType === AccountRelationship.Coapp ||
          acc?.relationType === AccountRelationship.Main,
      )
      .map(account => account.accountNumber);

    if (filteredAccounts?.length) {
      const { data: accountData } = await getApplicationsByAccount({
        accountId: filteredAccounts,
        status: [
          ApplicationStatus.Created,
          ApplicationStatus.Submitted,
          ApplicationStatus.InProgress,
          ApplicationStatus.Servicing,
        ],
      });
      if (accountData?.getApplications?.applications?.length) {
        setApplicationInProgressData &&
          setApplicationInProgressData({
            inProgress: true,
            accountNo:
              accountData.getApplications.applications.length === 1
                ? accountData.getApplications.applications[0].accountId
                : undefined,
            type: accountData.getApplications.applications.map(
              application => application?.applicationType,
            ),
            applicationsData: accountData.getApplications.applications,
          });
      }
    }
  };

  const onPayAllAccountsWithBalanceSubmit = async () => {
    const allAccountsWithBalance = await multicheckState.getAllAccountsWithBalance(
      true,
    );
    await navigate(ROUTES.MULTI_PAY_PAYMENT_AMOUNTS, {
      state: {
        accountParams: allAccountsWithBalance,
      },
    });
  };

  const onRenderSelectAllCheckbox = () => (
    <FormControlLabel
      control={
        <Checkbox
          name={'selectAll'}
          checked={multicheckState.areAllWithBalanceSelected}
          onChange={async e => {
            await multicheckState.toggleSelectAllWithBalance(e.target.checked);
          }}
          color={'primary'}
        />
      }
      disabled={multicheckState.selectAllDisabled}
      label={`${t('SELECT_ALL_WITH_AMOUNT_DUE')}${
        multicheckState.checkedOptions().length
          ? ` (${multicheckState.checkedOptions().length})`
          : ''
      }`}
    />
  );

  const classes = useStyles();

  return (
    <>
      <AccountLayout>
        <Helmet>
          <title>{t('MY_ACCOUNTS')}</title>
        </Helmet>

        <div className={classes.mainArea}>
          {Boolean(error || multicheckState.isCurrentChargesError) && (
            <Alerts
              isOpen
              severity={'error'}
              variant={'standard'}
              message={t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY')}
            />
          )}
          {(loading || multicheckState.areCurrentChargesLoading) && (
            <Backdrop forceOpen />
          )}

          {Boolean(detailList) && (
            <Grid
              container
              style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
            >
              <Grid item style={{ marginBottom: '1em' }} xs={12}>
                <Typography
                  className={classes.colorTextPrimary}
                  component={'span'}
                  variant={'h1'}
                >
                  {t('MY_ACCOUNTS')}
                </Typography>
                <Grid item md={12}>
                  <Box marginTop={2} marginBottom={0}>
                    {!loading && (
                      <AccountGroupDropdown
                        onSelect={() => {
                          onResetParams();
                        }}
                      />
                    )}
                  </Box>
                  {!loading && (
                    <SortFilterPageHeader
                      onAccountSearch={onAccountSearch}
                      onAccountSort={onAccountSort}
                      onAccountPageChange={onAccountPageChange}
                      accountListParams={accountListParams}
                      totalCount={totalCount}
                    />
                  )}
                </Grid>
              </Grid>

              {!loading && (
                <>
                  <BroadcastMessage />
                  <Grid item xs={12}>
                    <AccountList
                      onRenderSelectAll={onRenderSelectAllCheckbox}
                      groupId={selectedGroupId}
                      accounts={accounts || []}
                      multicheckState={multicheckState}
                      onViewDetails={account => {
                        if (onSelectAccount) {
                          onSelectAccount(account);
                        }
                        setSelectedAccountParams(selectedGroupId!, account);
                      }}
                      onPayAll={onPayAllAccountsWithBalanceSubmit}
                      accountsListCacheUpdate={accountsListCacheUpdate}
                    />
                  </Grid>

                  <Grid item xs={12} className={classes.buttonRow}>
                    <Grid item>
                      <Typography variant={'body2'}>
                        {detailList?.timestamp &&
                          `${t('AMOUNT_DUE_INCLUDES')} ${format(
                            parseISO(detailList?.timestamp),
                            'MMM dd, yyyy',
                          )}.`}
                      </Typography>
                    </Grid>
                    <Grid item className={classes.buttonContainer}>
                      <Button
                        disabled={multicheckState.getSelected().length === 0}
                        size={'large'}
                        className={classes.button}
                        onClick={() =>
                          navigate(ROUTES.MULTI_PAY_PAYMENT_AMOUNTS, {
                            state: {
                              accountParams: multicheckState.getSelectedAccounts(),
                            },
                          })
                        }
                      >
                        {t('PAY_SELECTED')}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: '2em' }}>
                    <SecureSitePromo />
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </div>
      </AccountLayout>
    </>
  );
}
